/*
 * Flat-calendar custom element. This will put styled calendar widget on a
 * page, that expands to full width and height of parent element.
 */

// First Build template
// It contains style and list of placeholders for calendar days.
const template = document.createElement('template');
const style = document.createElement('style');
style.innerHTML = `
  :host {
    display: block;
    height: calc(100vh - 73px);
    overflow: hidden;
  }
  ul {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  li {
    float: left;
    display: block;
    width: calc(100% / 7);
    text-align: center;
    list-style-type: none;
    height: calc(100% / 7);
  }
  li:nth-child(n+1):nth-child(-n+7) {
    font-weight: bold;
    font-size: 110%;
  }
  .event,
  li:hover:nth-child(n+8) {
    border-radius: 5px;
    background-color: #5b9bd5;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  li:hover:nth-child(n+8) {
    font-size: 110%;
  }
  .prev-month,
  .next-month {
    color: rgba(0,0,0,.3);
    font-weight: 900;
  }
  .event:hover {
    color: #5b9bd5;
    font-size: 110%;
    font-weight: 900;
    border: 2px solid #5b9bd5;
    border-radius: 5px;
    margin: -2px;
  }
`;

const ul = document.createElement('ul');
['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].forEach(day => {
  const li = document.createElement('li');
  li.innerText = day;
  ul.appendChild(li);
});

for (let i = 0; i < 42; ++i) {
  let li = document.createElement('li');
  ul.appendChild(li);
}
template.content.appendChild(style);
template.content.appendChild(ul);

class FlatCalendarElement extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({mode: 'open'});
    shadow.appendChild(template.content.cloneNode(true));
    this._markedDates = new Set();
    this.date = new Date();

    this._clickHandler = (event) => {
      const element = this._getEventTargetTag(event, 'LI');
      if (!element.dataset.date) {
        return false
      }
      let ev = new CustomEvent('date', {
        detail: new Date(element.dataset.date)
      })
      this.dispatchEvent(ev);
      return true;
    }
  }

  _getEventTargetTag(event, tagName) {
    if (typeof event.originalTarget !== 'undefined' && event.originalTarget.tagName === tagName) {
      return event.originalTarget;
    }
    const element = event.composedPath().find(element => element.tagName === tagName);
    if (!element) {
      throw new Error(`Cannot get target for event with tag name ${tagName}`)
    }
    return element;
  }

  connectedCallback() {
    this.addEventListener('click', this._clickHandler);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this._clickHandler);
  }

  set date(date) {
    const maxDate = new Date(date);
    const minDate = new Date(date)
    this._date = date;
    // Set minDate to Sunday, previous month
    minDate.setDate(1);
    minDate.setDate(1 - minDate.getDay());
    // Set maxDate to Saturday, next month
    maxDate.setMonth(maxDate.getMonth() + 1);
    maxDate.setDate(1);
    maxDate.setDate(maxDate.getDate() - 1);
    maxDate.setDate(maxDate.getDate() + 6 - maxDate.getDay());
    const dateEntries = this.shadowRoot.querySelectorAll('li');

    let d = new Date(minDate);
    for (let i = 7; i < 49; ++i) {
      let li = dateEntries[i];
      li.innerText = d.getDate().toString();
      li.dataset.date = new Date(d);
      if (this._markedDates.has(this._getDateKey(d))) {
        li.setAttribute('class', 'event');
      } else {
        li.removeAttribute('class');
      }

      if (d.getMonth() < date.getMonth()) {
        li.classList.add('prev-month');
      } else if (d.getMonth() > date.getMonth()) {
        li.classList.add('next-month');
      }
      d.setDate(d.getDate() + 1);
    }

  }

  get date() {
    return this._date;
  }

  _getDateKey(date) {
    if (!date instanceof Date) return;
    return date.getFullYear() * 100000 + date.getMonth() * 100 + date.getDate();
  }

  markDate(date) {
    if (!date instanceof Date) return;
    let key = this._getDateKey(date);
    if (isNaN(key)) return;
    this._markedDates.add(key);
    this.shadowRoot.querySelectorAll('li')
      .forEach(li => {
        let date = new Date(li.dataset.date);
        if (isNaN(date.getTime())) {
          return;
        }
        if (this._getDateKey(date) == key) {
          li.classList.add('event');
        }
      });
  }

  removeMarkedDates() {
    this._markedDates = new Set();
    this.shadowRoot.querySelectorAll('.event').forEach(el => el.classList.remove('event'));
  }
}

customElements.define('flat-calendar', FlatCalendarElement)

module.exports = {
  FlatCalendarElement
};
