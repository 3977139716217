class SinglePageLayoutElement extends HTMLElement {
  constructor() {
    super();
    const template = document.getElementById('layout');
    if (template && template.content) {
      this.attachShadow({mode: 'open'})
        .appendChild(template.content.cloneNode(true));
    }
  }

  connectedCallback() {
    if (this.hasOwnProperty('title')) {
      let value = this.title;
      delete this.title;
      this.title = value;
    }
  }

  get title() {
    return this.getAttribute('title');
  }

  set title(value) {
    this.setAttribute('title', value);
  }
}


class SinglePageTemplateElement extends HTMLTemplateElement {
  static get observedAttributes() {
    return ['oninit'];
  }

  constructor() {
    super();
    this._oninit = function() {};
    let init = this.getAttribute('oninit') || this.oninit;
    if (init) {
      this.oninit = init;
    }
  }

  connectedCallback() {
    if (this.hasOwnProperty('oninit')) {
      let value = this.oninit;
      delete this.oninit;
      this.oninit = value;
    }
  }

  attributeChangedCallback(attrName, oldValue, newValue) {
    if (oldValue === newValue) return;
    if (attrName === 'oninit') {
      this._oninit = new Function(newValue);
    }
  }

  match(pathname) {
    const pattern = this.getAttribute('path');
    const regexp = new RegExp('^' + pattern);
    return regexp.test(pathname);
  }

  set oninit(functionBody) {
    if (typeof functionBody === 'function') {
      this._oninit = functionBody;
    } else {
      //this._oninit = new Function(functionBody);
      this.setAttribute('oninit', functionBody);
    }
  }

  get oninit() {
    return this._oninit;
  }

  get title() {
    return this.getAttribute('title');
  }

  set title(title) {
    this.setAttribute('title', title);
  }

  init(content) {
    if (typeof this._oninit !== 'function') return;
    this._oninit.call(content);
  }

  disconnectedCallback() {
    this._oninit = null;
  }
}

class SinglePageContentElement extends HTMLElement {
  render(template) {
    if (!template instanceof HTMLTemplateElement) {
      console.error('template attribute must be an instance of HTMLTemplate element');
      return;
    }
    const clone = document.importNode(template.content, true);
    this.appendChild(clone);
    template.oninit.call(this);
  }
}

class SinglePageAppElement extends HTMLElement {
  constructor() {
    super();
    this._content = this.querySelector('single-page-content');

    this._linkClickedCallback = event => {
      const target = event.target;
      if (target.tagName !== 'A') return;
      if (target.host !== window.location.host) return;
      try {
        this.go(target.pathname, target.href);
        event.preventDefault();
        event.stopPropagation();
      } catch (e) {
        console.error(e);
      }
    };

    this._popstateCallback = event => {
      console.log(event);
      try {
        this.go(window.location.pathname, window.location.toString());
      } catch (e) {
        console.error(e);
      }
    };
    this.templates = [];
    for (let i = 0; i < this.children.length; ++i) {
      if (this.children[i] instanceof SinglePageTemplateElement) {
        this.templates.push(this.children[i]);
      }
    }
  }

  connectedCallback() {
    // No content to replace. There is nothing to do
    if (!this._content) return;

    this.addEventListener('click', this._linkClickedCallback);
    window.addEventListener('popstate', this._popstateCallback);
    window.addEventListener('load', _ => {
      this.go(window.location.pathname, window.location.toString());
    });
  }

  disconnectedCallback() {
    this.removeEventListener('click', this._linkClickedCallback);
    window.removeEventListener('popstate', this._popstateCallback);
  }

  go(pathname, config) {
    const template = this.templates.find(template => template.match(pathname));
    if (!template) throw new Error(`Template not found for path: ${pathname}`);
    history.pushState(null, null, this._getHref(pathname, config));
    this._replaceContent(template);
    if (template.title) {
      document.title = template.title;
    }
  }

  _replaceContent(template) {
    const newContent = document.createElement('single-page-content');
    newContent.render(template);
    this._content.dispatchEvent(new Event('unload'));
    this._content.parentNode.replaceChild(newContent, this._content);
    this._content = newContent;
  }

  _getHref(pathname, config) {
    if (typeof config === 'string') {
      pathname = config;
    } else if (typeof config === 'object' && config.href) {
      pathname = config.href;
    }

    if (typeof config === 'object' && config.search) {
      pathname += '?' + new URLSearchParams(config.search).toString();
    }
    
    return pathname || '/';
  }
}

customElements.define('single-page-layout', SinglePageLayoutElement);
customElements.define('single-page-template', SinglePageTemplateElement, {
  extends: 'template'
});
customElements.define('single-page-content', SinglePageContentElement);
customElements.define('single-page-app', SinglePageAppElement);

exports = {
  SinglePageLayoutElement,
  SinglePageTemplateElement,
  SinglePageContentElement,
  SinglePageAppElement
};
