const OBSERVED_ATTRIBUTES = [
    'href' ,'username', 'email'
];

class LiUserElement extends HTMLLIElement {
  static get observedAttributes() {
    return OBSERVED_ATTRIBUTES;
  }

  constructor() {
    super();
    this._link = document.createElement('a');
    this._userEl = document.createElement('strong');
    this._emailEl = document.createElement('p');

    this._link.appendChild(this._userEl);
    this._link.appendChild(this._emailEl);
    this.appendChild(this._link);
  }

  get href() {
    return this._link.getAttribute('href');
  }

  set href(value) {
    this._link.setAttribute('href', value);
  }

  get username() {
    return this._userEl.innerText;
  }

  set username(value) {
    this._userEl.innerText = value;
  }

  get email() {
    return this._emailEl.innerText;
  }

  set email(value) {
    this._emailEl.innerText = value;
  }

  connectedCallback() {
    for (let propname of OBSERVED_ATTRIBUTES) {
      this._setupProperty(propname);
    }
  }

  attributeChangedCallback(attr, oldValue, newValue) {
    if (newValue === oldValue) return;
    this[attr] = newValue;
  }

  _setupProperty(propname) {
    if (this.hasOwnProperty(propname)) {
      let value = this[propname];
      delete this[propname];
      this[propname] = value;
    }
  }
}

customElements.define('li-user', LiUserElement, { extends: 'li' });

module.exports = {
  LiUserElement
};
