const _ = require('lodash');
const axios = require('axios');
const Parser = require('js-expression').Parser;
const DateOnly = require('dateonly');

function init(config) {
  const api = `/api/inventory/${config.collection}`;
  const {
    container,
    order,
    template,
    who,
  } = config;
  // First form should be for adding new entry
  const newForm = container.querySelector('form');

  try {
    bindNewForm();
    getAndDisplayEntries();
  } catch (e) {
    console.error(e);
  }

  function bindNewForm() {
    const [inputDate, inputWho, inputQuantity] = newForm.querySelectorAll('input');
    inputDate.valueAsDate = new Date();
    inputWho.value = who;
    newForm.addEventListener('submit', onNewFormSubmit);
  }

  async function getAndDisplayEntries() {
    const res = await axios.get(api, {params: {orderId: order.id}});
    const entries = res.data.reverse();
    entries.forEach(appendEditForm);
  }

  function appendEditForm(entry) {
    const tpl = template.content.querySelector('form');
    const form = document.importNode(tpl, true);
    const inputs = form.querySelectorAll('input');
    inputs.forEach(input => {
      if (input.type === 'date') {
        input.value = new DateOnly(entry[input.name]).toISOString();
      } else {
        input.value = entry[input.name];
      }
      input.addEventListener('change', () => {
        const event = new Event('submit');
        form.dispatchEvent(event);
      });
    });
    const button = form.querySelector('button.fa-trash');
    button.addEventListener('click', onDelete);
    form.addEventListener('submit', onEditFormSubmit);
    newForm.insertAdjacentElement('afterend', form);
  }

  async function onNewFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const body = {
      date: new DateOnly().toISOString(),
      who,
      name: _.get(order, 'product.name', ''),
      quantity: 0,
      orderId: _.get(order, 'id', null),
    };
    const inputs = event.target.querySelectorAll('input');
    let quantityInput;
    inputs.forEach(input => {
      let value;
      if (input.type === 'date') {
        value = new DateOnly(input.value).toISOString();
      } else if (input.name === 'quantity') {
        value = Parser.evaluate(input.value);
        quantityInput = input;
      } else {
        value = input.value;
      }
      body[input.name] = value;
    });

    if (!body.who || !body.quantity || !body.date) return;

    if (!body.orderId) {
      throw new Error('Cannot add entry: Missing orderId');
    }
    try {
      const res = await axios.post(api, body);
      appendEditForm(res.data);
      if (quantityInput) {
        quantityInput.value = '';
      }
      emitQuantityChangedEvent();
    } catch (e) {
      console.error(e);
    }
  }

  async function onEditFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const body = {
      date: new DateOnly().toISOString(),
      who,
      name: _.get(order, 'product.name', ''),
      quantity: 0,
      orderId: _.get(order, 'id', null),
    };
    let _id;
    const inputs = event.target.querySelectorAll('input');
    inputs.forEach(input => {
      let value;
      if (input.type === 'date') {
        value = new DateOnly(input.value).toISOString();
      } else if (input.name === 'quantity') {
        value = Parser.evaluate(input.value);
        input.value = value;
      } else if (input.name === '_id') {
        _id = input.value;
      } else {
        value = input.value;
      }
      body[input.name] = value;
    });
    if (!body.who || !body.quantity || !body.date) return;
    if (!_id) {
      throw new Error('Cannot edit entry: Missing _id');
    }
    if (!body.orderId) {
      throw new Error('Cannot edit entry: Missing orderId');
    }

    try {
      await axios.put(`${api}/${_id}`, body);
      emitQuantityChangedEvent();
    } catch (e) {
      console.error(e);
    }
  }

  async function onDelete(event) {
    event.preventDefault();
    event.stopPropagation();
    const _id = event.target.parentElement.querySelector('input[name="_id"]');
    const form = event.target.closest('form');

    if (!_id && !_id.value) return;
    if (!form) return;
    if (!confirm('Czy na pewno usunąc ten wpis?')) return;

    try {
      await axios.delete(`${api}/${_id.value}`);
      form.parentElement.removeChild(form);
      emitQuantityChangedEvent();
    } catch (e) {
      console.error(e);
    }
  }

  function emitQuantityChangedEvent() {
    const quantities = container.querySelectorAll('input[name="quantity"]');
    let total = 0.0
    for (let input of quantities) {
      total += parseFloat(input.value) || 0.0;
    }
    const event = new CustomEvent('quantity', {detail: total});
    container.dispatchEvent(event);
  }
}

module.exports.init = init;
