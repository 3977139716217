const toggle = require('./toggle');
const axios = require('axios');
const DateOnly = require('dateonly');

function arrayCellValue(colDef, array) {
  if (colDef && !isNaN(colDef.index) && array[colDef.index]) {
    return parseFloat(array[colDef.index]).toFixed(2);
  }
  return '';
}

class ArrayElementRenderer {
  init(params) {
    this.el = document.createElement('div');
    this.el.setAttribute('class', 'align-right');
    this.refresh(params);
  }

  getGui() { return this.el; }

  refresh(params) {
    this.el.innerHTML = arrayCellValue(params.colDef, params.value);
  }
}

class ArraySumRender {
  init(params) {
    this.el = document.createElement('div');
    this.el.setAttribute('class', 'align-right');
    this.refresh(params);
  }

  getGui() { return this.el; }

  refresh(params) {
    var sum = params.value
      .reduce((a, b) => a + (b || 0), 0);

    this.el.innerHTML = parseFloat(sum).toFixed(2);
  }
}

class ArrayElementEditor {
  init(params) {
    this.input = document.createElement('input');
    this.input.style = 'width: 100%';
    this.input.setAttribute('class', 'align-right');
    this.input.value = params.charPress || arrayCellValue(params.column.colDef, params.value);

    if (params && params.column && params.column.colDef && !isNaN(params.column.colDef.index)) {
      this.index = params.column.colDef.index;
    } else {
      this.index = -1;
    }
    this.value = params.value;
  }

  getGui() { return this.input; }

  afterGuiAttached() { this.input.focus(); }

  getValue() { 
    if (this.index > -1) {
      this.value[this.index] = parseFloat(this.input.value.replace(',', '.'));
    }
    return this.value;
  }

}

async function fillGrid(grid, range) {
  const rowData = [];
  const startDate = new DateOnly(range.start).toISOString();
  const endDate = new DateOnly(range.end).toISOString();
  const params = {
    params: {startDate, endDate}
  };

  try {
    const res = await axios.get('/api/extruders', params);
    const date = new Date(range.end);
    let item = res.data.shift();
    let datevalue = new DateOnly(date).valueOf();
    let startvalue = new DateOnly(range.start).valueOf();
    do {
      if (item && item.date > datevalue) {
        item = res.data.shift();
        continue;
      }
      if (item && item.date == datevalue && item.shift === '2') {
        rowData.push({
          date: new DateOnly(item.date).toISOString(),
          name: item.name,
          shift: item.shift.toString(),
          produced: item.produced,
        });
        item = res.data.shift();
      } else {
        rowData.push({
          date: new DateOnly(datevalue).toISOString(),
          name: '',
          shift: '2',
          produced: Array(9).fill('')
        });
      }

      if (item && item.date == datevalue && item.shift === '1') {
        rowData.push({
          date: new DateOnly(item.date).toISOString(),
          name: item.name,
          shift: item.shift.toString(),
          produced: item.produced,
        });
        item = res.data.shift();
      } else {
        rowData.push({
          date: new DateOnly(datevalue).toISOString(),
          name: '',
          shift: '1',
          produced: Array(9).fill('')
        });
      }
      date.setDate(date.getDate() - 1);
      datevalue = new DateOnly(date).valueOf();
    } while (datevalue >= startvalue);

    grid.api.setRowData(rowData);
    grid.api.sizeColumnsToFit();
  } catch (e) {
    console.error(e);
  }
}

function init(content) {
  const range = content.querySelector('date-range-select');
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    columnDefs: [
      {
        field: 'date', headerName: 'Data',
        width: 112,
      },
      {
        field: 'name', headerName: 'Pracownik', width: 80,
        editable: true,
      },
      {
        field: 'shift', headerName: 'Zm.', width: 60,
        enableCellEdit: false, enableColumnMenu: false, enableHiding: false

      },
      {
        field: 'produced', headerName: 'I',
        index: 0,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'II',
        index: 1,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'III',
        index: 2,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'IV',
        index: 3,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'V',
        index: 4,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'VI',
        index: 5,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'VII',
        index: 6,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'VIII',
        index: 7,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'IX',
        index: 8,
        cellRenderer: ArrayElementRenderer,
        cellEditor: ArrayElementEditor,
        editable: true,
        width: 66
      },
      {
        field: 'produced', headerName: 'Razem',
        editable: false,
        cellRenderer: ArraySumRender,
        width: 86,
      }
    ],
    rowData: null,
    onCellEditingStopped: function(params) {
      const row = params.data;
      const date = new DateOnly(row.date);
      const url = `/api/extruders/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/${row.shift}`;

      if (params.column.colDef.field === 'produced') {
        let index = Math.floor((params.value.length - params.rowIndex - 1) / 2);
        let shift = params.data.shift;
        let value = params.value.reduce((a, b) => a + b, 0);
        grid.api.refreshCells({
          rowNodes: [params.node],
          columns: ['produced_9'],
          force: true,
        });
      }
      body = {
        shift: row.shift.toString(),
        name: row.name,
        produced: row.produced
      };
      axios.put(url, body)
        .catch(err => console.error(err));
    },
    onGridReady: () => {
      const search = new URLSearchParams(window.location.search);
      range.value = search.get('range') || 'week';
      fillGrid(grid, range);
    }
  };
  grid.gridOptions = gridOptions;
  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });

  range.addEventListener('change', event => {
    if (range.value === '') return;
    document.querySelector('single-page-app').go(window.location.pathname, {
      search: {
        range: range.value
      }
    });
  });
}

exports.init = init;
