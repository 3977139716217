const pdfMake = require('./pdfmake-fonts.js');

class PDFMakeElement extends HTMLElement {
  constructor() {
    super();
    this._docDefinition = null;
    this._iframe = document.createElement('iframe');

    const shadow = this.attachShadow({mode: 'closed'});
    const style = document.createElement('style');
    style.innerText = ':host {display: block;}';
    this._iframe.style.width = '100%';
    this._iframe.style.height = '100%';
    shadow.appendChild(style);
    shadow.appendChild(this._iframe);
  }

  set docDefinition(docDefinition) {
    this._docDefinition = docDefinition;
    const pdf = pdfMake.createPdf(docDefinition);
    pdf.getBase64(content => {
      this._iframe.setAttribute('src', 'data:application/pdf;base64,' + content);
    });
  }

  get docDefinition() {
    return this._docDefinition;
  }
}

customElements.define('pdf-make', PDFMakeElement);

module.exports = {
  PDFMakeElement,
};
