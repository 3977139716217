const Parser = require('js-expression').Parser;

class ExpressionCellEditor {
  init(params) {
    this.input = document.createElement('input');
    this.input.type = 'text';
    this.input.style = 'width: 100%';
    this.input.value = params.charPress || params.value || '';
  }

  getGui() { return this.input; }

  afterGuiAttached() {
    this.input.focus();
  }

  getValue() {
    if (!this.input.value) return '';
    return Parser.evaluate(this.input.value.replace(/,/g, '.'));
  }
}

module.exports = ExpressionCellEditor;
