const axios = require('axios');
const toggle = require('./toggle');
const CurrencyCellRenderer = require('./grid/currency-cell-renderer');

async function storePriceGroups(priceGroupId) {
  try {
    const res = await axios.get('/api/pricegroups');
    for (let group of res.data) {
      priceGroupId[group._id] = group.name;
    }
  } catch (e) {
    console.error(e);
  }
}

async function fillGrid(grid) {
  const params = {
    full: true,
  };

  try {
    const res = await axios.get('/api/customers/', {params});
    grid.api.setRowData(res.data);
  } catch (e) {
    console.error(e);
    grid.api.setRowData([]);
  } finally {
    grid.api.sizeColumnsToFit();
  }
}

module.exports.init = function init(content) {
  const priceGroupId = {};
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    columnDefs: [
      {
	field: 'name', headerName: 'Nazwa',
	cellRenderer: params => `<a href="/sales/customers/${params.value}/" style="display:block">${params.value}</a>`
      },
      {
	field: 'priceGroupId', headerName: 'Grupa cenowa',
	cellRenderer: params => {
	  return priceGroupId[params.value] || params.value;
	}
      },
      {
	field: 'saldo', headerName: 'Należności',
	cellRenderer: CurrencyCellRenderer,
      },
    ],
    rowData: null,
    onGridReady: () => {
      storePriceGroups(priceGroupId)
	.then(() => fillGrid(grid));
    },
  };
  grid.gridOptions = gridOptions;

  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });
}
