'use strict';
const DateOnly = require('dateonly');

module.exports.init = function init(content) {
  const app = document.querySelector('single-page-app');
  const flatCalendar = content.querySelector('flat-calendar');
  const flatCalendarControl = content.querySelector('flat-calendar-control');

  flatCalendarControl.calendar = flatCalendar;
  flatCalendar.addEventListener('date', event => {
    const date = new DateOnly(event.detail);
    const datePath = date.toISOString().replace(/-/g, '/');
    const url = `/reports/daily/${datePath}/`;

    app.go(url);
  });
}

