module.exports = function updateSearch(select) {
  let name = select.name;
  const search = new URLSearchParams(window.location.search);
  if (!name && select.tagName.toUpperCase() === 'DATE-RANGE-SELECT') {
    name = 'range';
  }
  search.set(name, select.value);
  document.querySelector('single-page-app')
    .go(window.location.pathname, {search});
}

