class AgGridQuickFilterElement extends HTMLInputElement {
  constructor() {
    super();
    this._grid = null;
    this._oninput = null;
  }

  connectedCallback() {
    this._grid = this._getGrid();
    if (!this._grid) return;
    this._oninput = event => {
      this._grid.api.setQuickFilter(this.value);
    };
    this.addEventListener('input', this._oninput);
  }

  disconnectedCallback() {
    if (this._oninput) {
      this.removeEventListener('input', this._oninput);
    }
  }

  _getGrid() {
    if (this._grid) return this._grid;
    if (this.getAttribute('for')) {
      this._grid = document.getElementById(this.getAttribute('for'));
    } else {
      this._grid = document.querySelector('ag-grid');
    }
    return this._grid;
  }
}

customElements.define('ag-grid-quickfilter', AgGridQuickFilterElement, {
  extends: 'input'
});

exports = {
  AgGridQuickFilterElement
}
