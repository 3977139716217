const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const ProductTypeFilter = require('./grid/product-type-filter');
const ProductLinkRenderer = require('./grid/product-link-cell-renderer');
const NumberCellRenderer = require('./grid/number-cell-renderer');
const CurrencyCellRenderer = require('./grid/currency-cell-renderer');
const getSnapshotUrl = require('./util/get-snapshot-url');
const getOrderSnapshotUrl = require('./util/get-order-snapshot-url');


function total(grid, column) {
  let t = 0;
  grid.api.forEachNodeAfterFilter(node => {
    t += node.data[column] || 0;
  })
  return t;
}

module.exports.init = function init(content) {
  const [_1_, _2_, _3_, year, month, day, _4_] = location.pathname.split('/');
  const showall = content.querySelector('#product-value-showall');
  const grid = content.querySelector('#product-value-grid');
  content.querySelector('#calendar').innerHTML = `&nbsp;${day}/${month}/${year}`;
  grid.setAttribute('title', `Wartość produktów z dnia ${day}/${month}/${year}`);
  grid.gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        headerName: 'Produkt',
        field: 'name',
        filter: ProductTypeFilter,
        cellRenderer: ProductLinkRenderer,
        pinnedRowCellRenderer: function() { return ''; },
        editable: false
      },
      {
        headerName: 'Stan',
        field: 'snapshot',
        suppressFilter: true,
        width: 140,
        editable: false,
        cellClass: 'align-right',
        cellRenderer: NumberCellRenderer,
        pinnedRowCellRenderer: function() { return ''; },
      },
      {
        headerName: 'Cena jedn.',
        field: 'price',
        suppressFilter: true,
        width: 140,
        editable: false,
        cellRenderer: CurrencyCellRenderer,
        pinnedRowCellRenderer: function() { return 'Razem'; },
        cellClass: 'align-right',
      },
      {
        headerName: 'Wartość',
        field: 'value',
        suppressFilter: true,
        width: 140,
        editable: false,
        cellRenderer: CurrencyCellRenderer,
        cellClass: 'align-right',
      }
    ],
    isExternalFilterPresent: () => {
      let showall = document.getElementById('product-value-showall');
      return showall && !showall.checked;
    },
    doesExternalFilterPass: node => {
      if (!node.data) { return false; }
      return node.data.snapshot || node.data.stocktaking;
    },
    rowData: null,
    onGridReady: async () => {
      const date = `${year}-${month}-${day}`;
      const snapshotUrl = getSnapshotUrl(date);
      const orderSnapshotUrl = getOrderSnapshotUrl(date);
      const snapshotMap = new Map();
      const [products, priceGroups, snapshots] =
        await Promise.all([
          axios.get('/api/products?prices=1'),
          axios.get('/api/pricegroups'),
          axios.get(snapshotUrl),
        ]);
      console.log(priceGroups.data[0]._id);
      const priceGroupId = priceGroups.data[0]._id;
      snapshots.data.forEach(s => snapshotMap.set(s.productId, s.items));
      const rowData = products.data.map(p => Object.create({
        productId: p._id,
        name: p.name,
        snapshot: snapshotMap.get(p._id) || '',
        price: p.prices[priceGroupId] || 0,
        value: (p.prices[priceGroupId] || 0) * (snapshotMap.get(p._id || 0))
      }));
      grid.api.setRowData(rowData);
      grid.api.sizeColumnsToFit();
      grid.api.setPinnedBottomRowData([{
        productId: '',
        name: '',
        snapshot: '',
        price: '',
        value: total(grid, 'value')
      }])
    }
  };
  showall.addEventListener('change', () => grid.api.onFilterChanged());
  const toggleHandler = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleHandler);
  });
}
