const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const intmoney = require('./intmoney').parse;
const NumberCellRenderer = require('./grid/number-cell-renderer');
const CurrencyCellRenderer = require('./grid/currency-cell-renderer');

function monthName(date) {
  const months = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień'
  ];
  return months[date.getMonth()];
}

async function init(content) {
  let product;
  const searchParams = new URLSearchParams(window.location.search);
  const monthParam = parseInt(searchParams.get('month'));
  const yearParam = parseInt(searchParams.get('year'));

  const currentDate = new Date();
  currentDate.setDate(1);
  if (!isNaN(yearParam)) {
    currentDate.setFullYear(yearParam);
  }

  if (!isNaN(monthParam)) {
    currentDate.setMonth(monthParam);
  }


  content.querySelectorAll('.action a').forEach(link => {
      link.onclick = changeMonth
    });

  try {
    // GET /api/products/:id/
    const res = await axios.get('/api' + window.location.pathname);

    product = res.data;
    updateTitle();
    setupPriceGroupGrid();
    setupProductGrid();
  } catch (e) {
    console.error(e);
  }

  function changeMonth(event) {
    event.preventDefault();
    event.stopPropagation();
    const [_, hash] = event.target.href.split('#');
    switch(hash) {
      case 'next-month':
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case 'prev-month':
        currentDate.setMonth(currentDate.getMonth() - 1);
        break;
      default:
        return;
    }
    const {protocol, host, pathname} = window.location;
    const params = new URLSearchParams({
      month: currentDate.getMonth(), year: currentDate.getFullYear()
    });
    const url = `${protocol}\/\/${host}${pathname}?${params.toString()}`;
    history.pushState(null, null, url);
    updateTitle();
    fillProductGrid();
  }

  function updateTitle() {
    const title = `Stan ${product.name} na ${monthName(currentDate)} ${currentDate.getFullYear()}`;
    document.title = title;
    try {
      content.querySelector('.action span').innerText = title;
    } catch (e) {}
  }

  function moveEntries(dest, src, date, attributeName) {
    let result = 0;
    while (src.length && src[0].date == date) {
      let item = src.shift();
      result += item.quantity;
      dest.entries.push({
        day: '',
        name: item.who,
        produced: attributeName === 'produced' ? item.quantity : '',
        sold: attributeName === 'sold' ? item.quantity : '',
      });
    }
    dest[attributeName] += result;
    return result;
  }

  async function setupProductGrid() {
    const grid = content.querySelector('#product-grid');
    const gridOptions = {
      defaultColDef: { cellClass: 'align-right' },
      rowSelection: 'multiple',
      enableColResize: true,
      animateRows: true,
      columnDefs: [
        { headerName: 'Dzień', field: 'day', width: 160, cellRenderer: 'agGroupCellRenderer'  },
        { headerName: 'Kto', field: 'name', width: 268, cellClass: 'text-left'  },
        { headerName: 'Prod', field: 'produced', cellRenderer: NumberCellRenderer  },
        { headerName: 'Sprz', field: 'sold', cellRenderer: NumberCellRenderer  },
        { headerName: 'Stan', field: 'current', cellRenderer: NumberCellRenderer  },
        { headerName: 'Remanent', field: 'stocktaking', cellRenderer: NumberCellRenderer  },
      ],
      rowData: null,
      getNodeChildDetails: rowItem => {
        if (rowItem.entries && rowItem.entries.length > 0) {
          return {
            group: true,
            expanded: false,
            children: rowItem.entries,
            field: 'day',
            key: rowItem.day
          }
        }
        return null;
      },
      onGridReady: () => fillProductGrid()
    };
    grid.gridOptions = gridOptions;
    const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
    content.addEventListener('unload', () => {
      toggle.removeListener(toggleCallback);
    });
  }

  async function fillProductGrid() {
    const grid = content.querySelector('#product-grid');
    const snapshotDate = new DateOnly(
      new Date(currentDate.getFullYear(),
               currentDate.getMonth(),
               currentDate.getDate() - 1
    ));
    const startDate = new DateOnly(currentDate);
    // endDate is last day of month
    const endDate = new DateOnly(
      new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
    );

    const snapshotUrl = `/api/inventory/snapshot/${snapshotDate.getFullYear()}/${snapshotDate.getMonth() + 1}/${snapshotDate.getDate()}`;
    const params = {
      productId: product._id,
      startDate: startDate.toISOString(),
      endDate:   endDate.toISOString()
    };

    try {
      const rowData = [];
      const [snapshots, additions, issues, stocktakings] = await Promise.all([
        axios.get(snapshotUrl),
        axios.get('/api/inventory/additions', {params}),
        axios.get('/api/inventory/issues', {params}),
        axios.get('/api/inventory/stocktaking', {params}),
      ]);
      additions.data.reverse();
      issues.data.reverse();
      let snapshot;
      let produced = 0;
      let sold = 0;
      let stocktakingItem = stocktakings.data.shift();

      try {
        snapshot = snapshots.data.find(s => s.productId === product._id).items;
      } catch (e) {
        snapshot = 0;
      }

      for (let date = new DateOnly(startDate); endDate >= date; date.setDate(date.getDate() + 1)) {
        let stocktaking = '';
        if (stocktakingItem && stocktakingItem.date == date.valueOf()) {
          stocktaking = stocktakingItem.quantity;
          stocktakingItem = stocktakings.data.shift();
        }
        const row = {
          day: date.getDate(),
          name: '',
          produced: 0,
          sold: 0,
          current: 0,
          stocktaking,
          entries: []
        };
        produced += moveEntries(row, additions.data, date.valueOf(), 'produced');
        sold += moveEntries(row, issues.data, date.valueOf(), 'sold');
        row.current = snapshot + produced - sold;
        rowData.push(row);
      }
      grid.api.setRowData(rowData);
      grid.api.setPinnedBottomRowData([
        {
          day: 'Suma',
          name: '',
          produced,
          sold,
          current: '',
          stocktaking: '',
          entries: []
        }
      ])
      grid.api.sizeColumnsToFit();
    } catch (e) {
      console.error(e);
    }
  }

  async function setupPriceGroupGrid() {
    let priceGroupNames = [];
    const grid = content.querySelector('#price-group-grid');
    const gridOptions = {
      columnDefs: [
        {
          headerName: 'Grupa',
          field: 'group'
        },
        {
          headerName: 'Cena',
          field: 'price',
          editable: true,
          cellRenderer: CurrencyCellRenderer
        }
      ],
      onCellEditingStopped: async params => {
        const priceGroupId = params.data.priceGroupId;
        params.value = intmoney(params.value);
        if (isNaN(params.value)) {
          console.error(`Bad price: ${params.value}`);
          return;
        }
        if (!priceGroupNames) {
          priceGroupNames = await getPriceGroupNames();
        }
        product.prices[priceGroupId] = params.value;
        axios.patch(`/api/products/${product._id}`, {
          prices: product.prices
        });
        setPriceGroupRowData(product.prices);
      },
      rowData: null,
      onGridReady: params => params.api.sizeColumnsToFit()
    };
    grid.gridOptions = gridOptions;
    const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
    content.addEventListener('unload', () => {
      toggle.removeListener(toggleCallback);
    });
    try {
      priceGroupNames = await getPriceGroupNames();
      setPriceGroupRowData(product.prices);
    } catch (e) {
      console.error(e);
    }

    async function getPriceGroupNames() {
      const res = await axios.get('/api/pricegroups');
      return res.data;
    }

    function setPriceGroupRowData(prices) {
      grid.api.setRowData(priceGroupNames.map(pg => new Object({
        priceGroupId: pg._id,
        group: pg.name,
        price: product.prices[pg._id]
      })));
      grid.api.sizeColumnsToFit();
    }
  }
}

module.exports.init = init;
