const axios = require('axios');

module.exports = async function fillDatalist(datalist, url, attribute='name') {
  const res = await axios.get(url);
  for (let item of res.data) {
    if (typeof item !== 'object') continue;
    const option = document.createElement('option');
    option.value = item[attribute];
    if (item.hasOwnProperty('_id')) {
      option.setAttribute('data-id', item._id);
    }
    datalist.appendChild(option);
  }
}
