class ButtonCellRenderer {
  init(params) {
    this.el = document.createElement('a');
    this.el.type = 'button';
    this.el.href = this._getHref(params);
    if (typeof params.onclick === 'function') {
      this.el.onclick = event => {
        event.preventDefault();
        event.stopPropagation();
        params.onclick(params.data);
      }
    }
    this.el.setAttribute('class', params.class);

    this.hide = params.hide || (row => false);
    this.refresh(params);
  }

  getGui() {
    return this.el;
  }

  refresh(params) {
    if (this.hide(params.data)) {
      this.el.style = 'display: none';
    } else {
      this.el.style = '';
    }
  }

  _getHref(params) {
    switch(typeof params.href) {
      case 'string':
        return params.href;
      case 'function':
        return params.href(params.data);
      default:
        return '#';
    }
  }
}

module.exports = ButtonCellRenderer;
