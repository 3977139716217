/*
 * Flat calendar control custom element
 * This element displays current year and month selected on flat-calendar
 * Additionally it displays arrows to change view to next or previous month
 */

const template = document.createElement('template');
template.innerHTML = `
  <style>
  ul {
    display: flex;
    cursor: default;
    list-style: none;
    margin-left: -1em;
    margin-bottom: 0;
    padding-left: 0;
  }
  li {
    padding: 0 0 0 1em;
    vertical-align: middle;

  }
  a {
    text-decoration: none;
    border-bottom: none;
    position: relative;
    color: #5b9bd5;
  }
  </style>
  <ul>
	  <li><a href="#" class="prev-month">&lt;</a></li>
	  <li><a href="#" class="next-month">&gt;</a></li>
	  <li>
		  <span class="current-month">January</span> <span class="current-year">2018</span>
	  </li>
  </ul>
`;

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

class FlatCalendarControlElement extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({mode: 'open'});
    shadow.appendChild(template.content.cloneNode(true));
    this._calendar = null;
    this.prevMonth = shadow.querySelector('.prev-month');
    this.nextMonth = shadow.querySelector('.next-month');
    this.currentMonth = shadow.querySelector('.current-month');
    this.currentYear = shadow.querySelector('.current-year');

    this.prevMonthClicked = event => {
      event.preventDefault();
      event.stopPropagation();
      if (!this._calendar) return;
      let date = new Date(this._calendar.date);
      if (!date) return;
      date.setMonth(date.getMonth() - 1);
      this._calendar.date = date;
      this._updateLabels(date);
      const monthChange = new CustomEvent('month-change', {
        detail: date
      });
    }

    this.nextMonthClicked = event => {
      event.preventDefault();
      event.stopPropagation();
      if (!this._calendar) return;
      let date = new Date(this._calendar.date);
      if (!date) return;
      date.setMonth(date.getMonth() + 1);
      this._calendar.date = date;
      this._updateLabels(date);
      const monthChange = new CustomEvent('month-change', {
        detail: date
      });
    }

  }

  connectedCallback() {
    this.prevMonth.addEventListener('click', this.prevMonthClicked);
    this.nextMonth.addEventListener('click', this.nextMonthClicked);
  }

  disconnectedCallback() {
    this.prevMonth.removeEventListener('click', this.prevMonthClicked);
    this.nextMonth.removeEventListener('click', this.nextMonthClicked);
  }

  set calendar(calendar) {
    this._calendar = calendar;
    this._updateLabels(calendar.date);
  }

  get calendar() {
    return this._calendar;
  }

  _updateLabels(date) {
    this.currentMonth.innerText = MONTHS[date.getMonth()];
    this.currentYear.innerText = date.getFullYear();
  }
}

customElements.define('flat-calendar-control', FlatCalendarControlElement);

module.exports = {
  FlatCalendarControlElement
};
