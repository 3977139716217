const axios = require('axios');

module.exports.init = async function init(content) {
  const form = content.querySelector('form');
  const errorMessage = content.querySelector('#error-message');
  const successMessage = content.querySelector('#success-message');
  const oldPassword = content.querySelector('[name=old-password]');
  const newPassword = content.querySelector('[name=new-password]');
  const passwordRepeat = content.querySelector('[name=password-repeat]')
  const button = content.querySelector('.button.primary');
  let userId;

  newPassword.addEventListener('keyup', checkSend);
  passwordRepeat.addEventListener('keyup', checkSend);
  form.addEventListener('submit', changePassword);

  content.addEventListener('unload', () => {
    newPassword.removeEventListener('keyup', checkSend);
    passwordRepeat.removeEventListener('keyup', checkSend);
    form.removeEventListener('submit', changePassword);
  });

  setTimeout(() => oldPassword.focus(), 500);

  try {
    const response = await axios.get('/api/users/me/');
    userId = response.data._id;
  } catch (e) {
    console.error(e);
  }

  function checkSend() {
    if (!newPassword.value || !passwordRepeat.value) {
      button.setAttribute('disabled', 'disabled');
    } else if (newPassword.value === passwordRepeat.value) {
      button.removeAttribute('disabled');
    } else {
      button.setAttribute('disabled', 'disabled');
    }
  }

  async function changePassword(event) {
    const passwordChangeUrl = `/api/users/${userId}/password`;
    event.preventDefault();

    errorMessage.innerText = '';
    successMessage.innerText = '';

    try {
      const response = await axios.put(passwordChangeUrl, {
        oldPassword: oldPassword.value,
        newPassword: newPassword.value
      });
      oldPassword.value = '';
      newPassword.value = '';
      passwordRepeat.value = '';
      oldPassword.focus();
      successMessage.innerText = "Hasło zostało zmienione";
    } catch (e) {
      console.error(e);
      if (e.response.status == 403) {
        errorMessage.innerText = "Nieprawidłowe hasło";
      }
    }
  }
}
