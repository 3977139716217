const DateOnly = require('dateonly');

module.exports = function getOrderSnapshotUrl(date) {
  let d = new Date(date);
  d.setDate(d.getDate() - 1);
  const datePath = new DateOnly(d).toISOString().replace(/-/g, '/');
  return `/api/inventory/snapshot/alt-orders/${datePath}`;
}


