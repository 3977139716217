const axios = require('axios');

module.exports = async function menu() {
  if (!localStorage.getItem('token')) return;
  const menuItems = document.querySelectorAll('#menu > ul > li');

  try {
    const response = await axios.get('/api/users/me/');
    const permissions = response.data.permissions;

    // Special case: add admin permission, when user has admin role
    if (response.data.role === 'admin') {
      permissions.push('admin');
    }
    for (let menuItem of menuItems) {
      if (!menuItem.dataset.permission) continue;
      const permission = menuItem.dataset.permission;
      if (!permissions.find(x => x === permission)) continue;
      menuItem.removeAttribute('style');
    }
  } catch (e) {
    console.error(e);
  }
}
