class DateCellEditor {
  init(params) {
    this.input = document.createElement('input');
    this.input.type = 'date';
    this.input.style = 'width: 100%';
    this.input.value = params.value || '';
  }

  getGui() { return this.input; }

  afterGuiAttached() {
    this.input.focus();
  }

  getValue() {
    return this.input.value;
  }
}

module.exports = DateCellEditor;
