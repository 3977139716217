class AutcompleteCellEditor {
  init(params) {
    this.input = document.createElement('input');
    this.input.type = 'text';
    this.input.style = 'width: 100%';
    this.input.value = params.charPress || params.value || '';
    if (params.datalist) {
      this.input.setAttribute('list', params.datalist);
    }
  }

  getGui() { return this.input; }

  afterGuiAttached() {
    this.input.focus();
  }

  getValue() {
    return this.input.value;
  }
}

module.exports = AutcompleteCellEditor;
