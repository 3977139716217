/**
 * ProductLinkCellRenderer
 * ag-grid cell renderer that display link to product page in cell
 */
class ProductLinkCellRenderer {
  init(params) {
    this.gui = document.createElement('a');
    this.gui.setAttribute('style', 'display: block');
    this.refresh(params);
  }

  getGui() {
    return this.gui;
  }

  refresh(params) {
    this.gui.innerHTML = params.value;
    this.gui.setAttribute('href', this._linkToProduct(params.data));
  }

  _linkToProduct(data) {
    if (data.orderId) {
      return `/order-id/${data.orderId}/`;
    } else if (data.productId) {
      return `/products/${data.productId}/`;
    }
    return '#';
  }
}

module.exports = ProductLinkCellRenderer;
