const axios = require('axios');
const DateOnly = require('dateonly');
const CurrencyCellRenderer = require('./grid/currency-cell-renderer');
const updateSearch = require('./update-search');
const toggle = require('./toggle');

function makeRow(item) {
  return {
    _id: item._id,
    date: item.date ? new DateOnly(item.date).toISOString() : '',
    customer: item.customer || '',
    value: Math.round(item.value || 0),
    paid: Math.round(item.paid || 0),
  };
}

function setPinnedBottomRowData(grid) {
  let value = 0, paid = 0;
  grid.api.forEachNodeAfterFilter(node => {
    value += node.data.value || 0;
    paid += node.data.paid || 0;
  });

  grid.api.setPinnedBottomRowData([{
    _id: '',
    date: '',
    customer: 'Razem',
    value,
    paid,
  }]);
}

async function fillGrid(grid, range, search) {
  const startDate = new DateOnly(range.start).toISOString();
  const endDate = new DateOnly(range.end).toISOString();
  const params = {
    startDate,
    endDate
  };

  try {
    const res = await axios.get('/api/receipts', {params});
    const rowData = res.data.map(makeRow);
    grid.api.setRowData(rowData);
    setPinnedBottomRowData(grid);
  } catch (e) {
    console.error(e);
    grid.api.setRowData([]);
  } finally {
    grid.api.sizeColumnsToFit();
  }
}

module.exports.init = function init(content) {
  const quickfilter = content.querySelector('input[for=receipts-grid]');
  const newButton = content.querySelector('#new-receipt');
  const range = content.querySelector('date-range-select');
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    columnDefs: [
      {
	field: 'date', headerName: 'Data',
	cellRenderer: row => `<a href="/sales/receipts/${row.data._id}/" style="display:block">${row.value}</a>`,
	pinnedRowCellRenderer: function() { return ''; },
	width: 160,
      },
      {
	field: 'customer', headerName: 'Klient',
	cellRenderer: row => `<a href="/sales/customers/${row.value}/" style="display:block">${row.value}</a>`,
	pinnedRowCellRenderer: row => row.value,
      },
      {
	field: 'value', headerName: 'Wartość',
	cellRenderer: CurrencyCellRenderer,
	width: 100
      },
      {
	field: 'paid', headerName: 'Zapłacono',
	cellRenderer: CurrencyCellRenderer,
	width: 100
      },
    ],
    rowData: null,
    onGridReady: () => {
      const search = new URLSearchParams(window.location.search);
      range.value = search.get('range') || 'month';
      quickfilter.addEventListener('keyup', ev => {
	setPinnedBottomRowData(grid);
      })
      fillGrid(grid, range, search);
    },
  };
  grid.gridOptions = gridOptions;
  range.addEventListener('change', event => updateSearch(range));
  newButton.addEventListener('click', newReceipt);

  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });

  async function newReceipt() {
    const app = document.querySelector('single-page-app');
    const receipt = {
      date: new DateOnly().valueOf(),
      customer: '',
      value: 0,
      paid: 0,
      entries: []
    };
    try {
      const response = await axios.post('/api/receipts', receipt);
      if (response.data && response.data._id) {
	app.go(`/sales/receipts/${response.data._id}/`);
      } else {
	throw new Error('Missing response data');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
