/**
 * Project stores money as an integer with with two decimal places
 * That means $1 is stored as 100
 *
 * This module provides functions that convert between string and internal
 * representaion of money
 */
class intmoney {
  constructor(value) {
    if (value instanceof intmoney) {
      this.value = value.valueOf();
    } else {
      this.value = parseInt(value) || 0;
    }
  }

  toString() {
    return (this.value / 100).toFixed(2);
  }

  valueOf() {
    return this.value;
  }

  static parse(value) {
    return parseInt(parseFloat(value).toFixed(2).replace('.', ''));
  }
}

module.exports = intmoney;
