/**
 * ProductTypeFilter
 * ag-grid column filter that filters products by category
 */
class ProductTypeFilter {
  constructor() {}
  init(params) {
    // Store this element to focus out context menu after choice been made
    this.overlay = document.getElementById('overlay');
    this.type = 'all';
    this.valueGetter = params.valueGetter;
    this.gui = document.createElement('div');
    this.gui.setAttribute('style', 'clear: both');
    this.gui.innerHTML = `
      <ul class="alt" style="margin-bottom: 0">
        <li><span style="display: block;padding: 12px 24px;">Wszystkie produkty</span></li>
        <li><span style="display: block;padding: 12px 24px;">Reklamówki</span></li>
        <li><span style="display: block;padding: 12px 24px;">Nadruki</span></li>
        <li><span style="display: block;padding: 12px 24px;">Bloki</span></li>
        <li><span style="display: block;padding: 12px 24px;">Woreczki</span></li>
      </ul>
    `;
    this.patterns = [
      null,
      /^E?RN? *\d/i,
      /^E?RN *\d/i,
      /^BS? *\d/i,
      /^C?\d/i,
    ];
    this.gui.querySelectorAll('li').forEach((el, i) => {
      el.addEventListener('click', () => {
        console.log('click', el, i);
        this.pattern = this.patterns[i]
        params.filterChangedCallback(); 
        if (this.overlay) {
          this.overlay.click();
        }
      });
    })
  }

  getGui() { return this.gui }

  doesFilterPass(params) {
    return this.pattern.test(this.valueGetter(params));
  }

  isFilterActive() {
    return this.pattern;
  }

  getModel() { return {value: this.pattern}}
  setModel() { }
}

module.exports = ProductTypeFilter;
