const axios = require('axios');
const toggle = require('./toggle');
const DateOnly = require('dateonly');
const CurrencyCellRenderer = require('./grid/currency-cell-renderer');

function linkToReceipt(params) {
  const date = new DateOnly(params.value).toISOString();
  const _id = params.data._id;
  
  return `<a style="display:block" href="/sales/receipts/${_id}/">${date}</a>`;
}

async function fillGrid(grid, customer) {
  const params = { customer };

  try {
    const response = await axios.get('/api/receipts', { params });
    grid.api.setRowData(response.data);
  } catch (e) {
    console.error(e);
    grid.api.setRowData([]);
  } finally {
    grid.api.sizeColumnsToFit();
  }
}

module.exports.init = function init(content) {
  const customerName = decodeURIComponent(window.location.pathname.split('/')[3]);
  const balance = content.querySelector('#balance');
  const grid = content.querySelector('#customer-receipts-grid');
  content.querySelector('#customer-name').innerText = `Rachunki klienta ${customerName}`;
  document.title =  `Rachunki klienta ${customerName}`;
  const gridOptions = {
    columnDefs: [
      {
	field: 'date', headerName: 'Data',
	cellRenderer: linkToReceipt,
      },
      {
	field: 'value', headerName: 'Wartość',
	cellRenderer: CurrencyCellRenderer,
      },
      {
	field: 'paid', headerName: 'Zapłacono',
	cellRenderer: CurrencyCellRenderer,
      },
    ],
    rowData: null,
    onGridReady: () => {
      fillGrid(grid, customerName)
	.then(() => {
	  let total = 0;
	  grid.api.forEachNode(node => total += (node.data.paid || 0) - (node.data.value || 0));
	  total = (total / 100).toFixed(2);
	  balance.innerText = ` ${total} zł`;
	  balance.classList.remove('hidden');
	});
    }
  };
  grid.gridOptions = gridOptions;

  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });
}
