const _                    = require('lodash'),
      DateOnly             = require('dateonly');
      CurrencyCellRenderer = require('./grid/currency-cell-renderer');

const ion = (ionization) => ionization ? `${ionization}x` : 'nie';

function getUnit(order) {
  const unit = _.get(order, 'quantity.unit', '');
  switch(unit) {
    case 'pkg':  return 'op.';
    case 'K':    return 'tys.';
    default:     return '???';
  }
}

function getRollers(order) {
  let ordered = parseFloat(_.get(order, 'quantity.ordered', 0) ||  0),
      pieces  = parseFloat(_.get(order, 'product.packaging.pieces', 0) ||  0),
      tracks  = parseFloat(_.get(order, 'product.film.tracks', 0) ||  1),
      revs    = parseFloat(_.get(order, 'product.film.revs', 0) ||  1000),
      height  = parseFloat(_.get(order, 'product.size.height', 0) ||  100),
      roller,
      result;

  pieces = ordered * pieces / 1000;
  roller = tracks * revs / (10 * height);
  result = pieces / roller;

  return result;
}

function packageWeight(product) {
  if (!product) return 0;
  const width     = parseFloat(_.get(product, 'size.width') || 0),
        height    = parseFloat(_.get(product, 'size.height') || 0),
        fold      = parseFloat(_.get(product, 'size.fold') || 0),
        thickness = parseFloat(_.get(product, 'film.thickness') || 0),
        density   = parseFloat(_.get(product, 'film.density') || 0),
        punch     = parseFloat(_.get(product, 'film.punch') || 0),
        pieces    = parseFloat(_.get(product, 'packaging.pieces') || 0);

  const result = ((width + 2 * fold) * 2 * height *
          density * thickness * pieces *
          ((100 - punch) / 100)) / 10000000;
  return result;
}

function controlWeight(product) {
  if (!product) return 0;
  const control = parseFloat(_.get(product, 'packaging.control') || 0),
        pieces  = parseFloat(_.get(product, 'packaging.pieces') || 0);

  if (!control || !pieces) return packageWeight(product);
  return packageWeight(product) * control / pieces;
}

function instructionsCol(order) {
  const instructions = _.get(order, 'product.packaging.instructions', false);
  if (instructions) {
    return {text: `Pakowanie: ${instructions}`, colSpan: 2};
  }
  return false;
}

function paletteCol(order) {
  const palette = _.get(order, 'product.packaging.palette', false)
  if (palette) {
    return {text: `Paleta: ${palette}`, colSpan: 2};
  }
  return false;
}

function productionCol(order, options = {}) {
  const unit = _.get(order, 'quantity.unit', 'pkg');
  const pieces = _.get(order, 'product.packaging.pieces', '???');
  const unitDescription = unit === 'K' ? 'tysiącach' : `paczkach a'${pieces}szt`;
  const text =  `Wpisywać w ${unitDescription}: `;

  return _.extend({text, bold: true, colSpan: 2, rowSpan: 9}, options);
}

module.exports = function pdfOrder(order) {
  const customer    = _.get(order, 'detail.customer', '');
  const id          = _.get(order, 'id', '');
  const created_    = new DateOnly(_.get(order, 'detail.created', NaN));
  const created     = isNaN(created_.getDate()) ? '' : created_.toISOString();
  const due_        = new DateOnly(_.get(order, 'detail.due', NaN));
  const due         = isNaN(due_.getDate()) ? '' : due_.toISOString();
  const filmName    = _.get(order, 'product.film.name', '');
  const filmColor   = _.get(order, 'product.print.film', '');
  const productName = _.get(order, 'product.name', '');
  const detailNumber = _.get(order, 'detail.number', '');
  const index       = _.get(order, 'product.index', '');
  const productNameShort = _.get(order, 'product.name', '');
  const quantity    = _.get(order, 'quantity.ordered', 0) *
                      _.get(order, 'product.packaging.pieces', 0)

  const price       = _.get(order, 'product.price', 0);
  const currency    = _.get(order, 'product.currency', 'zł');
  const packageType = _.get(order, 'product.packaging.packageType',
                            'Karton');

  const packages    = _.get(order, 'product.packaging.packages', '');
  const unit        = getUnit(order);
  const revs        = _.get(order, 'product.film.revs', 0) / 1000 || '???';
  const rollers     = _.get(order, 'quantity.rollers') || getRollers(order) || '???';

  const ionization  = _.get(order, 'product.film.ionization', 0);
  const block       = _.get(order, 'product.packaging.block', '');
  const pieces      = _.get(order, 'product.packaging.pieces', '');
  const control     = _.get(order, 'product.packaging.control', pieces);
  const weight_     = controlWeight(order.product) || '???';
  const weight      = typeof weight_ === 'number' ? weight_.toFixed(2) : '???';
  const rows        = [];
  const fontSize = 17;

  let leftCols    = [];
  let rightCols   = [];

  const print = {
    front: [],
    back:  []
  };

  for (let i = 0; i < 4; ++i) {
    print.front.push(_.get(order, `product.print.front.${i}`, '...'));
    print.back.push(_.get(order, `product.print.back.${i}`, '...'));
  }

  leftCols.push([
    {text: `Zam:${id}\n${customer}`, bold: true},
    {text: `Od: ${created}\nDo: ${due}`}
  ])
  leftCols.push([
    {text: productName, colSpan: 2},
    ''
  ])
  if (detailNumber) {
    leftCols.push([
      {text: `Nr klienta: ${detailNumber}`, colSpan: 2},
      ''
    ])
  }
  if (index) {
    leftCols.push([
      {text: `Indeks: ${index}`, colSpan: 2},
      ''
    ])
  }
  leftCols.push([
    {text: `Ilość: ${quantity}`},
    {text: `Cena: ${new CurrencyCellRenderer({}).render({currency, value: price * 100})}`},
  ])
  leftCols.push([
    {text: `Blok: ${block}\n${packageType}: ${packages}`, rowSpan: 2},
    {text: `Waga ${control} szt\n${weight} kg`, rowSpan: 2},
  ])
  leftCols.push([
    '\n', '\n' 
  ])
  let instructions = instructionsCol(order);
  if (instructions) {
    leftCols.push([
      instructions,
      ''
    ])
  }
  let palette = paletteCol(order);
  if (palette) {
    leftCols.push([
      palette,
      ''
    ])
  }

  rightCols.push([
    {text: `${customer} zam: ${id}`, bold: true, colSpan: 2},
    ''
  ])

  rightCols.push([
    {text: `${filmName}\n${productNameShort}`, colSpan: 2, rowSpan: 2},
    ''
  ])
  rightCols.push([
    '', ''
  ])
  rightCols.push([
    {text: `Folia: ${filmColor}`, colSpan: 2},
    ''
  ])
  rightCols.push([
    {stack: [{ul: print.front}], rowSpan: 3},
    {stack: [{ul: print.back}], rowSpan: 3}
  ])
  rightCols.push([
    '\n', '\n'
  ])
  rightCols.push([
    '', ''
  ])
  rightCols.push([
    {text: `Ilość: ${quantity}`, colSpan: 2},
    '\n'
  ])
  rightCols.push([
    'Obroty: ' + revs + ' tys.\n',
    'Wałki: ' + rollers + '\n'
  ])
  rightCols.push([
    {text: `${customer} zam:${id}`, bold: true, colSpan: 2, rowSpan: 2, fontSize},
    ''
  ])
  rightCols.push([
    '',
    '\n'
  ])
  rightCols.push([
    {text: filmName + '\n' + productNameShort, colSpan: 2, rowSpan: 2, fontSize},
    ''
  ])
  rightCols.push([
    '', ''
  ])
  rightCols.push([
    `Folia: ${filmColor}`,
    `Jonizacja: ${ion(ionization)}`
  ])
  rightCols.push([
    'Obroty: ' + revs + ' tys.',
    'Wałki: ' + rollers
  ])
  rightCols.push([
    {text: '\n\n\n\n\n\n', colSpan: 2, rowSpan: 2},
    ''
  ])
  rightCols.push([
    '', ''
  ])

  let rowSpan = rightCols.length - leftCols.length;
  leftCols.push([
    productionCol(order, {rowSpan}),
    ''
  ])

  while (leftCols.length + rightCols.length > 0) {
    let l = leftCols.shift() || ['', '']
    let r = rightCols.shift() || ['', '']
    rows.push(Array.prototype.concat(l, r))
  }

  return {
    pageSize: 'A4',
    content: [
    {
      table: {
        headerRows: 0,
        color: '#444',
        widths: ['25%', '25%', '25%', '25%'],
        body: rows
      }
    }
    ],
    defaultStyle: {
      font: 'DejaVuSans',
    }
  };
};

