/**
 * Cell renderer for ag-grid
 * Displays right-aligned number as float
 */
class NumberCellRenderer {
  init(params) {
    this.el = document.createElement('div');
    this.el.setAttribute('class', 'align-right');
    this.refresh(params);
  }

  getGui() {
    return this.el;
  }

  refresh(params) {
    let number = parseFloat(params.value);
    if (!number) {
      this.el.innerHTML = '';
    } else {
      this.el.innerHTML = this._format(number);
    }
  }

  _format(number) {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

module.exports = NumberCellRenderer;

