const DateOnly = require('dateonly');

/**
 * Cell renderer for ag-grid
 * Display date in format YYYY-MM-DD
 */
class DateCellRenderer {
  init(params) {
    this.el = document.createElement('div');
    this.refresh(params);
  }

  getGui() {
    return this.el;
  }

  refresh(params) {
    const re = /\d\d\d\d-\d\d-\d\d/;
    let date = params.value;
    console.log(date, re.test(date));
    if (re.test(date)) {
      this.el.innerHTML = date;
    } else if (!isNaN(parseInt(date))) {
      this.el.innerHTML = new DateOnly(date).toISOString();
    } else {
      this.el.innerHTML = '';
    }
  }
}

module.exports = DateCellRenderer;
