const axios = require('axios');
const menu = require('./menu');

// Define HTML custom elements
require('./custom-elements');

const app = document.querySelector('single-page-app');
const templateLogin = document.querySelector('template[path="/login/"]');

// Axios configuration
//axios.defaults.baseURL = 'http://localhost:9000';
axios.interceptors.request.use(req => {
  const token = localStorage.getItem('token');
  if (token) {
    req.headers['Authorization'] = 'bearer ' + token;
  }
  return req;
}, function(error) {
  console.log(error);
  return Promise.reject(error);
});

axios.interceptors.response.use(res => res,
  error => {
    if (!error.response) {
      console.error('Empty response');
      //app.render('internal-server-error');
    }
    if (error.response.status == 401) {
      const params = new URLSearchParams({location: window.location.toString()});
      if (window.location.pathname !== '/login/') {
        app.go(`/login/?${params}`);
      }
    }
    return Promise.reject(error);
  });

// Global template initiation functions
window.accounts = require('./accounts.js');
window.accountEdit = require('./account-edit.js');
window.accountNew = require('./account-new.js');
window.changePassword = require('./change-password.js');
window.currency = require('./currency.js');
window.employees = require('./employees.js');
window.employeedaily = require('./employeedaily.js');
window.extruders = require('./extruders.js');
window.extrudersChart = require('./extruders-chart.js');
window.inventory = require('./inventory.js');
window.inventoryStatus = require('./inventory-status.js');
window.inventoryState = require('./inventory-state.js');
window.inventoryStocktaking = require('./inventory-stocktaking.js');
window.login = require('./login.js');
window.mainPage = require('./main-page.js');
window.order = require('./order.js');
window.orders = require('./orders.js');
window.orderid = require('./orderid.js');
window.products = require('./products.js');
window.product = require('./product.js');
window.stocktaking = require('./stocktaking.js');
window.dailyreport = require('./daily-report.js');
window.dailyreportcalendar = require('./daily-report-calendar.js');
window.weightreport = require('./weight-report.js');
window.reportcalendar = require('./report-calendar.js');
window.productvaluereport = require('./product-value-report.js');
window.altordervaluereport = require('./alt-order-value-report.js');

window.logout = require('./logout.js');

// Sales template initiation functions
window.receipt = require('./receipt.js');
window.receipts = require('./receipts.js');
window.customer = require('./customer.js');
window.customers = require('./customers.js');

menu();
