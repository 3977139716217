function today() {
  let date = new Date();
  date.setHours(0,0,0,0);
  return date;
}

class DateRangeSelectElement extends HTMLElement {
  constructor() {
    super();
    this.customFormat = /^\d{4}-\d{2}-\d{2}:\d{4}-\d{2}-\d{2}$/;
    this._start = today();
    this._end = today();
    this.select = this._makeSelect();
    this.overlay = this._makeOverlay();
    if (this.hasOwnProperty('value')) {
      let value = this.value;
      delete this.value;
      this.value = value;
    }
    let style = document.createElement('style');
    style.innerHTML = `
      date-range-select {
        display: inline-block;
      }

      date-range-select .overlay {
        position: fixed;
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        padding: 7% 25%;
        bottom: 0;
        background-color: white;
        z-index: 999999;
      }

      date-range-select .overlay .actions {
        float: right;
      }
    `;
    this.appendChild(style);
    let div = document.createElement('div');
    div.appendChild(this.select);
    div.appendChild(this.overlay);
    this.appendChild(div);

  }

  _makeSelect() {
    const options = [
      {name: '', value: ''},
      {name: 'Okres', value: 'custom'},
      {name: 'Dzisiaj', value: 'today'},
      {name: 'Tydzień', value: 'week'},
      {name: 'Miesiąc', value: 'month'},
      {name: 'Poprzedni miesiąc', value: 'last-month'},
      {name: 'Ostatnie 12 miesięcy', value: '12-months'},
      {name: 'Wszystkie', value: 'all'},
    ];
    let select = document.createElement('select');

    select.setAttribute('name', 'range');
    for (let option of options) {
      let opt = document.createElement('option');
      opt.setAttribute('value', option.value);
      opt.innerText = option.name;
      select.appendChild(opt);
    }
    select.addEventListener('change', event => {
      this.value = event.target.value;
    });

    return select;
  } 

  _makeOverlay() {
    const self = this;
    let overlay = document.createElement('div');

    overlay.innerHTML = `
      <h2>Wybierz zakres dat</h2>
      <div class="row">
        <div class="col-6 col-12-small">
        <label>Od: <input type="date" name="start"></label>
        </div>
        <div class="col-6 col-12-small">
        <label>Do: <input type="date" name="end"></label>
        </div>
      </div>
      <ul class="actions">
        <li><button class="primary align-right">Pokaż</button></li>
      </div>
    `;
    overlay.className = 'overlay';
    overlay.addEventListener('change', event => {
      if (event.target.tagName.toUpperCase() === 'INPUT') {
        event.stopPropagation();
      }
    })
    overlay.addEventListener('click', event => {
      if (event.target.tagName.toUpperCase() !== 'BUTTON') {
        return;
      }
      if (event.target.classList.contains('primary')) {
        const [start, end] = this.overlay.getElementsByTagName('input');
        self.value = [
          start.value,
          end.value
        ].join(':');

        self.dispatchEvent(new Event('change'));
      }
      self.hideOverlay();
    });
    return overlay;
  }

  showOverlay() {
    let [inputStart, inputEnd] = this.overlay.getElementsByTagName('input');
    this.overlay.style.display = 'block';
    inputStart.value = this._start.toISOString().substring(0, 10);
    inputEnd.value   = this._end.toISOString().substring(0, 10);
  }

  hideOverlay() {
    this.overlay.style.display = 'none';
  }

  setCustomOptionText(text) {
    const option = this.select.querySelector('option[value=""]');
    if (!option) return;
    option.innerHTML = text;
  }

  get value() {
    if (this.select.value === '') {
      return [
        this._start.toISOString().substring(0, 10),
        this._end.toISOString().substring(0, 10)
      ].join(':')
    }
    return this.select.value;
  }

  set value(value) {
    if (this.customFormat.test(value)) {
      const [start, end] = value.split(":");
      this._start = new Date(start);
      this._end = new Date(end);
      this.setCustomOptionText(start + ' &mdash; ' + end);
      this.select.value = '';
      return;
    } else {
      this.setCustomOptionText('');
    }
    this.select.value = value;
    switch(this.value) {
      case 'today':
        this._start = today();
        this._end = today();
        this._end.setDate(this._end.getDate() + 1);
        break;
      case 'week':
        this._start = today();
        this._end = today();
        this._start.setDate(this._start.getDate() - 7);
        break;
      case 'month':
        this._start = today();
        this._end = today();
        this._start.setDate(1);
        break;
      case 'last-month':
        this._start = today();
        this._end = today();
        this._start.setDate(1);
        this._start.setMonth(this._start.getMonth() - 1);
        this._end.setDate(1);
        break;
      case '12-months':
        this._start = today();
        this._end = today();
        this._start.setMonth(this._start.getMonth() - 12);
        break;
      case 'all':
        this._start = today();
        this._end = today();
        this._start.setYear(1970);
        this._start.setMonth(0);
        this._start.setDate(0);
        break;
      case 'custom':
        this.showOverlay();
        break;
    }
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }
}

customElements.define('date-range-select', DateRangeSelectElement)

module.exports = {
  DateRangeSelectElement
};
