const _ = require('lodash');

module.exports = function pdfRoller(order) {
  const id = _.get(order, 'id', '');
  const customer    = _.get(order, 'detail.customer', '');
  const productName = _.get(order, 'product.name', '');
  const filmName = _.get(order, 'product.film.name', '');
  const ordered = _.get(order, 'quantity.rollers', '');

  const table = {
    headerRows: 0,
    color: '#444',
    widths: ['20%', '60%', '20%'],
    body: [
      [{text: id}, {text: customer, colSpan: 2}, ''],
      [{text: filmName, colSpan: 3, fontSize: 28}, '', ''],
      [{text: productName, colSpan: 2}, '', {text: `Wałki: ${ordered}`, fontSize: 14} ]
    ],
  };
  const docDefinition = {
    pageSize: 'A4',
    content: {table},
    defaultStyle: {
      font: 'DejaVuSans',
      fontSize: 24,
      bold: true,
      alignment: 'center',
    },
    pageMargins: [4, 4],
  };


  console.log(id);
  console.log(customer);
  console.log(productName);
  console.log(filmName);

  return docDefinition;
}
