const axios = require('axios');

module.exports.init = async function init(content) {
  const _id = window.location.pathname.split('/')[2];
  const removeButton = content.querySelector('#remove');
  const nameSlot = content.querySelector('#name');
  const checkboxes = Array.from(
    content.querySelectorAll('input[type=checkbox]')
  );


  try {
    const response = await axios.get(`/api/users/${_id}`);
    nameSlot.innerText = response.data.name;
    for (let permission of response.data.permissions) {
      content.querySelector(`input[name="${permission}"]`).checked = true;
    }
    for (let checkbox of checkboxes) {
      checkbox.addEventListener('click', save)
    }
  } catch (e) {
    console.error(e);
  }

  removeButton.addEventListener('click', remove);

  content.addEventListener('unload', () => {
    for (let checkbox of checkboxes) {
      checkbox.removeEventListener('click', save);
    }

    removeButton.removeEventListener('click', remove);
  });

  function save(event) {
    const url = `/api/users/${_id}/permissions`;
    const permissions = checkboxes
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.getAttribute('name'));

    axios.put(url, permissions);
  }

  async function remove(event) {
    if (confirm('Czy na pewno usunąć użytkownia?')) {
      await axios.delete(`/api/users/${_id}`);
      document.querySelector('single-page-app').go('/accounts/');
    }
  }
}
