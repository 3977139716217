const DateOnly = require('dateonly');

module.exports = function getSnapshotUrl(date) {
  let d = new Date(date);
  // We need to get snapshots from previous day
  // Stocktakings are made at beginning of day
  // They need to be compared with snapshots from previous day
  d.setDate(d.getDate() - 1);
  const datePath = new DateOnly(d).toISOString().replace(/-/g, '/');
  return `/api/inventory/snapshot/${datePath}`;
}

