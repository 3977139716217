const axios = require('axios');
const toggle = require('./toggle');
const ProductTypeFilter = require('./grid/product-type-filter');
const ButtonCellRenderer = require('./grid/button-cell-renderer')



async function init(content) {
  let canEdit = false;
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        field: 'name', headerName: 'Nazwa',
        filter: ProductTypeFilter,
        editable: () => canEdit,
        width: 200,
      },
      {
        field: 'weight', headerName: 'Waga',
        suppressFilter: true,
        cellClass: 'align-right',
        editable: () => canEdit,
        width: 120,
      },
      {
        field: 'packages', headerName: 'Ilość paczek',
        suppressFilter: true,
        cellClass: 'align-right',
        editable: () => canEdit,
        width: 120,
      },
      {
        field: 'pieces', headerName: 'Ilość sztuk',
        suppressFilter: true,
        cellClass: 'align-right',
        editable: () => canEdit,
        width: 120,
      },
      {
        field: 'punch', headerName: '% Wykrojnika',
        suppressFilter: true,
        cellClass: 'align-right',
        editable: () => canEdit,
        width: 120,
      },
      {
        field: '_id', headerName: '',
        suppressFilter: true,
        cellRenderer: ButtonCellRenderer,
        cellRendererParams: {
          "class": "icon fa-times",
          onclick: remove,
          hide: row => !canEdit || !row._id
        },
        width: 30
      },
      {
        field: '_id', headerName: '',
        suppressFilter: true,
        cellRenderer: ButtonCellRenderer,
        cellRendererParams: {
          "class": "icon fa-angle-right",
          href: product => `/products/${product._id}/`,
          hide: row => !row._id
        },
        width: 30
      },
    ],
    rowData: null,
    onCellEditingStopped: function(event) {
      if (!event.data) {
        return;
      }
      var params = {
        name: event.data.name,
        packages: parseInt(event.data.packages) || '',
        punch: parseFloat(event.data.punch) || '',
        pieces: parseInt(event.data.pieces) || '',
        weight: parseFloat(event.data.weight) || ''
      };

      if (event.data._id) {
        updateProduct(event.data._id, params);
      } else {
        createProduct(params);
      }
    },
    onGridReady: async () => {
      try {
        const response = await axios.get('/api/products');
        const rowData = response.data.map(product => {
          return {
            _id: product._id,
            name: product.name,
            weight: parseFloat(product.weight) || '',
            punch: parseFloat(product.punch) || '',
            pieces: parseInt(product.pieces) || '',
            packages: parseInt(product.packages) || ''
          };
        });
        // if user has permission to edit, add empty row for adding new products
        if (canEdit) {
          rowData.unshift({});
        }
        grid.api.setRowData(rowData);
      } catch (e) {
        console.error(e);
        grid.api.setRowData([]);
      }
    }
  };
  grid.gridOptions = gridOptions;
  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });
  grid.api.sizeColumnsToFit();
  try {
    const response = await axios.get('/api/users/me');
    const permissions = response.data.permissions;

    canEdit = permissions.indexOf('inventory.edit') >= 0;
    // if user has permission to edit, add empty row for adding new products
    if (canEdit && !grid.api.getModel().isEmpty()) {
      let rowData = [{}];
      grid.api.getModel().forEachNode(node => rowData.push(node.data));
      grid.api.setRowData(rowData);
    }
  } catch (e) {
    console.error(e);
  }

  function remove(product) {
    let text, url;

    if (!product._id) return;
    url = '/api/products/' + product._id;
    text = "Czy na pewno usunąć produkt?\n" +
      product.name;

    if (!confirm(text)) {
      return;
    }
    axios.delete(url)
      .then(function(rsp) {
        let rowData = [];
        grid.api.getModel().forEachNode(node => {
          if (node.data._id !== product._id) {
            rowData.push(node.data);
          }
        })
        grid.api.setRowData(rowData);
      })
      .catch(err => console.error(err));
  }

  function updateProduct(id, params) {
    axios.put(`/api/products/${id}`, params);
  }

  function createProduct(params) {
    axios.post('/api/products', params)
      .then(res => {
        let rowData = [];
        grid.api.getModel().forEachNode(node => rowData.push(node.data));
        rowData[0] = res.data;
        rowData.unshift({});
        grid.api.setRowData(rowData);
      })
      .catch(err => console.error(err));
  }
}

exports.init = init;
