const axios = require('axios');
const toggle = require('./toggle');
const ProductLinkCellRenderer = require('./grid/product-link-cell-renderer');
const NumberCellRenderer = require('./grid/number-cell-renderer');
const ProductTypeFilter = require('./grid/product-type-filter');

function getDate(pathname) {
  const parts = pathname.split('/');
  parts.pop();
  const day = parts.pop();
  const month = parts.pop();
  const year = parts.pop();

  return `${year}-${month}-${day}`;
}

function updateMap(map, entry, attribute) {
  const key = entry.productId || entry.orderId;
  let row = map.get(key);
  if (!row) {
    row = {
      orderId: null,
      productId: null,
      name: entry.name,
      additions: 0,
      subtractions: 0,
    };
  }
  if (entry.orderId) {
    row.orderId = entry.orderId;
  } else if (entry.productId) {
    row.productId = entry.productId;
  }
  row[attribute] += entry.quantity;
  map.set(key, row);
}

module.exports.init = function init(content) {
  const date = getDate(window.location.pathname);
  const grid = content.querySelector('ag-grid');
  const sectionTitle = content.querySelector('#section-title');
  const title = `Produkcja i sprzedaż w dniu ${date}`;
  document.title = title;
  sectionTitle.innerText = title;
  grid.setAttribute('title', title);
  const gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        field: 'orderId',
        headerName: 'Zam.',
        cellClass: 'align-right',
        suppressFilter: true,
        width: 100,
      },
      {
        field: 'name',
        headerName: 'Nazwa',
        filter: ProductTypeFilter,
        cellRenderer: ProductLinkCellRenderer,
      },
      {
        field: 'additions',
        headerName: 'Produkcja',
        cellClass: 'align-right',
        suppressFilter: true,
        width: 100,
        cellRenderer: NumberCellRenderer,
      },
      {
        field: 'subtractions',
        headerName: 'Sprzedaż',
        cellClass: 'align-right',
        suppressFilter: true,
        width: 100,
        cellRenderer: NumberCellRenderer,
      },
    ],
    rowData: null,
    onGridReady: async () => {
      try {
        const params = {
          params: {startDate: date, endDate: date}
        };
        const rows = new Map();
        const rowData = [];
        const [additions, issues] = await Promise.all([
          axios.get('/api/inventory/additions', params),
          axios.get('/api/inventory/issues', params)
        ]);
        additions.data.forEach(addition => updateMap(rows, addition, 'additions'));
        issues.data.forEach(issue => updateMap(rows, issue, 'subtractions'));
        for (let [_, row] of rows) {
          rowData.push(row);
        }
        grid.api.setRowData(rowData);
        grid.api.sizeColumnsToFit();
      } catch (e) {
        console.error(e);
      }
    }
  };
  grid.gridOptions = gridOptions;
  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });
}
