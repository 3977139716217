/*
 * Chart-js custom element. It contains options attribute. Once it is populated, a chart is drawn.
 * Element should have width and height set by the user styles.
 */

const Chart = require('chart.js');

// Build template
const template = document.createElement('template');
const style = document.createElement('style');
style.innerHTML = `
canvas {
  width: 100%;
  height: 100%;
}
`;

const canvas = document.createElement('canvas');

template.content.appendChild(style);
template.content.appendChild(canvas);

class ChartJsElement extends HTMLElement {
  constructor() {
    super();
    this._chart = null;
    this._connected = false;
    this._options = null;
    const shadow = this.attachShadow({mode: 'open'});
    shadow.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    this._connected = true;
    if (this._options) {
      this._makeChart();
    }
  }

  disconnectedCallback() {
    this._connected = false;
    this._chart = null;
  }

  set options(options) {
    this._options = options;
    if (this._connected) {
      this._makeChart();
    }
  }

  get options() {
    return this._options;
  }

  _makeChart() {
    const canvas = this.shadowRoot.querySelector('canvas');
    if (!canvas) {
      throw new Error('Missing canvas element');
    }
    const context = canvas.getContext('2d');
    if (!context) {
      throw new Error('Cannot get 2d drawing context');
    }
    if (this._chart) {
      this._chart.destroy();
    }
    this._chart = new Chart(context, this._options);
  }
}

customElements.define('chart-js', ChartJsElement);

module.exports = {
  ChartJsElement
};
