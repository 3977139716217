const toggle = require('./toggle');
const axios = require('axios');
const DateOnly = require('dateonly');

async function setupChart(chart, range) {
  const url = '/api/extruders';
  const startDate = new DateOnly(range.start).toISOString();
  const endDate = new DateOnly(range.end).toISOString();
  const params = {
    params: {startDate, endDate}
  };
  const options = {
    type: 'line',
    data: {
      datasets: [
        {
          data: [],
          label: 'Zmiana 1',
        },
        {
          data: [],
          label: 'Zmiana 2',
        }
      ],
      labels: []
    },
    options: {
      responsive: false,
      legend: {display: true},
      scales: {
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              beginAtZero: true,
            },
          },
        ]
      }
    },
    override: [
      {
        label: 'Zmiana 1',
        yAxisID: 'y-axis-1'
      },
      {
        label: 'Zmiana 2',
        yAxisID: 'y-axis-1'
      }
    ]
  };

  try {
    const res = await axios.get('/api/extruders', params);
    res.data.forEach(hist => {
      if (hist.shift != 1 && hist.shift != 2) return;
      let index = hist.shift - 1;
      let value = hist.produced.reduce((a, b) => (a || 0) + (b || 0), 0);
      let date = new DateOnly(hist.date).toISOString();
      options.data.datasets[index].data.unshift(value);
      if (index) return;
      options.data.labels.unshift(date);
    });
  } catch (e) {
    console.error(e);
  } finally {
    chart.options = options;
  }
}


function init(content) {
  const chart = content.querySelector('chart-js');
  const range = content.querySelector('date-range-select');
  const search = new URLSearchParams(window.location.search);

  range.value = search.get('range') || 'week';

  setupChart(chart, range);

  range.addEventListener('change', event => {
    document.querySelector('single-page-app').go(window.location.pathname, {
      search: {
        range: range.value
      }
    });
  });
}

exports.init = init;
