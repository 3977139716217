const axios = require('axios');
const getErrors = require('./get-errors');

module.exports.init = function init(content) {
  const app = document.querySelector('single-page-app');
  const form = content.querySelector('#user-form');
  const saveButton = content.querySelector('#save');
  const nameInput = content.querySelector('#name');
  const emailInput = content.querySelector('#email');
  const passwordInput = content.querySelector('#password');
  const passwordRepeatInput = content.querySelector('#password-repeat');
  const errorMessages = [
    ['name', content.querySelector('#name-error')],
    ['email', content.querySelector('#email-error')],
    ['password', content.querySelector('#password-error')],
    ['password-repeat', content.querySelector('#password-repeat-error')],
  ]

  form.addEventListener('submit', createUser);

  saveButton.addEventListener('click', createUser);

  async function createUser(event) {
    event.preventDefault();

    for (let [_, error] of errorMessages) {
      error.innerText = '';
    }
    if (passwordInput.value !== passwordRepeatInput.value) {
      let li = document.createElement('li');
      li.innerText = 'Hasła się nie zgadzają';
      errorMessages[errorMessages.length - 1][1].appendChild(li);
      return;
    }
    const data = {
      name: nameInput.value,
      email: emailInput.value,
      password: passwordInput.value,
    };

    try {
      const response = await axios.post('/api/users', data);
      const url = `/accounts/${response.data._id}`;
      console.log('submit', url);
      app.go(url);
    } catch (e) {
      console.error(e);
      const errors = getErrors(e.response);
      console.log(errors);
      for (let [attr, list] of errorMessages) {
        if (!errors.has(attr)) continue;
        for (let message of errors.get(attr)) {
          let li = document.createElement('li');
          li.innerText = message;
          list.appendChild(li);
        }
      }
    }
  }
}
