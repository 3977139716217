class TabbedContent extends HTMLElement {
  constructor() {
    super();
    this._tabs = [];
    this._panels = [];
  }

  connectedCallback() {
    this._tabs = Array.from(this.querySelectorAll('[role="tab"]'));
    this._panels = Array.from(this.querySelectorAll('[role="tabpanel"]'));
    this.addEventListener('click', this._tabClicked);
    this._show(this._tabs[0].hash);
  }

  disconnectedCalback() {
    this.removeEventListener('click', this._tabClicked);
  }

  _tabClicked(event) {
    if (event.target.getAttribute('role') !== 'tab') {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this._show(event.target.hash);
  }

  _show(id) {
    this._tabs.forEach(tab => {
      let match = tab.hash === id;
      tab.classList[match ? 'add' : 'remove']('selected');
      tab.setAttribute('aria-selected', match);
    });

    this._panels.forEach(panel => {
      let match = '#' + panel.id === id;
      panel.style.display = match ? '' : 'none';
      panel.setAttribute('aria-hiddne', match);
    });
  }
}

customElements.define('tabbed-content', TabbedContent);

module.exports = {
  TabbedContent,
};
