const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const AutocompleteCellEditor = require('./grid/autocomplete-cell-editor');
const ButtonCellRenderer = require('./grid/button-cell-renderer');
const DateCellRenderer = require('./grid/date-cell-renderer');
const DateCellEditor = require('./grid/date-cell-editor');
const ExpressionCellEditor = require('./grid/expression-cell-editor');
const ProductTypeFilter = require('./grid/product-type-filter');

function canEdit(user) {
  return user && user.permissions.length && user.permissions.includes('inventory.edit');
}

async function fillGrid(grid, range) {
  const url = '/api' + window.location.pathname;
  const startDate = new DateOnly(range.start).toISOString();
  const endDate = new DateOnly(range.end).toISOString();
  const params = {
    params: {startDate, endDate}
  };

  let rowData;
  try {
    const res = await axios.get(url, params);

    rowData = res.data.map(item => Object.create({
      _id: item._id,
      date: new DateOnly(item.date).toISOString(),
      orderId: item.orderId || '',
      productId: item.productId || '',
      name: item.name || '',
      who: item.who || '',
      quantity: item.quantity,
    }));
    // User has permission to edit.
    // Add empty row for inserting new entries
    if (grid.api.getColumnDef('date').editable) {
      rowData.unshift({
        date: new DateOnly().toISOString(),
        who: '',
        orderId: '',
        name: '',
        productId: null,
        quantity: ''
      });
    }
  } catch (e) {
    console.error(e);
    rowData = [];
  } finally {
    grid.api.setRowData(rowData);
    grid.api.sizeColumnsToFit();
  }
}

async function fillProductsDatalist(productsDatalist) {
  const res = await axios.get('/api/products/');
  res.data.forEach(product => {
    const option = document.createElement('option');
    option.value = product.name;
    option.setAttribute('data-id', product._id);
    productsDatalist.appendChild(option);
  });
}

async function fillEmployeeDatalist(employeeDatalist) {
  if (!employeeDatalist) {
    return;
  }
  const res = await axios.get('/api/inventory/employees');
  res.data.forEach(employee => {
    const option = document.createElement('option');
    option.value = employee._id;
    option.innerText = employee.name;
    employeeDatalist.appendChild(option);
  });
}

async function setOrderProductName(grid, row) {
  if (!row.orderId) return;
  const res = await axios.get('/api/orders/', {
    params: {orderId: row.orderId}
  });
  if (res.data.length != 1) return;
  const order = res.data[0];
  if (!order.product) return;
  if (!order.product.name) return;
  row.name = order.product.name;
  row.productId = null;
  grid.api.refreshCells();
}

async function setProductId(grid, productsDatalist, row) {
  const option = productsDatalist.querySelector(`option[value="${row.name}"]`);
  if (option && option.dataset && option.dataset.id) {
    row.productId = option.dataset.id;
  } else {
    row.productId = '';
  }
  row.orderId = '';
  grid.api.refreshCells();
}

function sanitizeParams(row) {
  const products = document.getElementById('products');
  return {
    date: new DateOnly(row.date).valueOf(),
    orderId: parseInt(row.orderId) || '',
    productId: row.orderId ? null : findProductId(products, row.name),
    name: row.name,
    who: row.who || '',
    quantity: parseFloat(row.quantity),
  }
}

function findProductId(content, name) {
  const options = content.querySelectorAll('#products option');

  for (let option of options) {
    if (option.value === name) {
      return option.dataset.id;
    }
  }

  return null;
}

async function createRow(grid, row) {
  const url = `/api${window.location.pathname}`;
  try {
    const rowData = [];
    const res = await axios.post(url, row);
    grid.api.forEachNode(node => rowData.push(node.data));
    rowData[0]._id = res.data._id;
    rowData.unshift({
      date: row.date,
      who: row.who,
      orderId: '',
      name: '',
      productId: '',
      quantity: '',
    });
    grid.api.setRowData(rowData);
    grid.api.setFocusedCell(0, 'name');
  } catch (e) {
    console.error(e);
  }
}

async function deleteRow(grid, row) {
  const text = `Czy na pewno usunąć wpis?
    Data: ${row.date}
    Nazwa: ${row.name}
    Ilość: ${row.quantity}
  `;

  if (!row._id) return;
  if (!confirm(text)) return;
  await axios.delete(`/api${window.location.pathname}${row._id}`);
  const rowData = [];
  grid.api.forEachNode(node => {
    if (node.data._id !== row._id) {
      rowData.push(node.data);
    }
  });
  grid.api.setRowData(rowData);
}

function init(content) {
  const validPaths = [
    '/inventory/orders/',
    '/inventory/additions/',
    '/inventory/issues/',
  ];

  if (!validPaths.includes(window.location.pathname)) {
    throw new Error(`Inventory view does not support path: ${window.location.pathname}`);
  }
  const whoHeader = window.location.pathname === '/inventory/additions/' ?
    'Pracownik' : 'Klient';

  const range = content.querySelector('date-range-select');
  const productsDatalist = content.querySelector('#products');
  const employeeDatalist = content.querySelector('#employees');
  const whoColumnCellEditorParams = employeeDatalist ? { datalist: 'employees' } : {};
  const grid = content.querySelector('#collection');
  const gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        field: 'date', headerName: 'Data',
        editable: false,
        cellRenderer: DateCellRenderer,
        cellEditor: DateCellEditor,
        suppressFilter: true,
        width: 120
      },
      {
        field: 'who', headerName: whoHeader,
        editable: false,
        cellEditor: AutocompleteCellEditor,
        cellEditorParams: whoColumnCellEditorParams,
        suppressFilter: true,
        /*
        cellClassRules: {
          'bg-danger': function(params) {
            if (!params.value && params.rowIndex === 0) return false;
            if (collection.typeaheadCompletion.length == 0) return false;
            return !collection.typeaheadCompletion.find(x => x.value === params.value || x === params.value);
          }
        }
        */
      },
      {
        field: 'orderId', headerName: 'Zamówienie',
        editable: false,
        suppressFilter: true,
        width: 100,
      },
      {
        field: 'name', headerName: 'Towar',
        editable: false,
        cellEditor: AutocompleteCellEditor,
        cellEditorParams: {datalist: 'products'},
        filter: ProductTypeFilter,
        cellClassRules: {
          'error': function (params) {
            return params.rowIndex != 0 && !params.data.orderId && !params.data.productId;
          }
        }
      },
      {
        field: 'quantity', headerName: 'Ilość',
        editable: false,
        cellEditor: ExpressionCellEditor,
        cellClass: 'text-right',
        suppressFilter: true,
        width: 100,
        cellClass: 'align-right',
      },
      {
        field: '_id', headerName: '',
        editable: false,
        cellRenderer: ButtonCellRenderer,
        suppressFilter: true,
        cellRendererParams: {
          "class": "icon fa-times",
          onclick: params => deleteRow(grid, params)
        },
        width: 30
      },
    ],
    rowData: null,
    onCellEditingStopped: async event => {
      const colDef = event.colDef;
      const rowEntity = event.node.data;

      if (colDef.field === 'name') {
        await setProductId(grid, productsDatalist, rowEntity);
      } else if (colDef.field === 'orderId') {
        await setOrderProductName(grid, rowEntity);
      }

      if (!rowEntity.name || !rowEntity.quantity) return;

      if (isNaN(rowEntity.quantity)) {
        throw new Error("quantity is not a number");
      }

      const params = sanitizeParams(rowEntity);

      if (rowEntity._id) {
        let url = `/api${window.location.pathname}${rowEntity._id}`;
        axios.put(url, params);
      } else {
        let url = `/api${window.location.pathname}`;
        createRow(grid, params);
      }
      grid.api.refreshCells();
    },
    onGridReady: async () => {
      const res = await axios.get('/api/users/me');
      const user = res.data;
      const search = new URLSearchParams(window.location.search);
      range.value = search.get('range') || 'week';

      if (canEdit(user)) {
        ['date', 'who', 'orderId', 'name', 'quantity'].forEach(colName => {
          grid.api.getColumnDef(colName).editable = true;
        });
      }
      fillGrid(grid, range);
    }
  };
  grid.gridOptions = gridOptions;

  fillProductsDatalist(productsDatalist);
  fillEmployeeDatalist(employeeDatalist);

  const toggleHandler = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleHandler);
  });

  range.addEventListener('change', event => {
    document.querySelector('single-page-app').go(window.location.pathname, {
      search: {
        range: range.value
      }
    });
  });
}

module.exports = {
  init
};
