const axios = require('axios');
const DateOnly = require('dateonly');

async function updateView(calendar, year, month) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  let currentMonth = months[calendar.date.getMonth()];
  let currentYear = calendar.date.getFullYear();
  month.innerText = currentMonth;
  year.innerText = currentYear;

  const startDate = new DateOnly(
    new Date(calendar.date.getFullYear(), calendar.date.getMonth(), 1)
  ).toISOString();
  const endDate = new DateOnly(
    new Date(calendar.date.getFullYear(), calendar.date.getMonth() + 1, 0)
  ).toISOString();
  calendar.removeMarkedDates();
  try {
    const res = await axios.get('/api/inventory/stocktaking', {params: {startDate, endDate}});
    res.data.forEach(stocktaking => {
      calendar.markDate(new DateOnly(stocktaking.date).toDate());
    });
  } catch (e) {
    console.error(e);
  }
}

function init(content) {
  const app = document.querySelector('single-page-app');
  const calendar = content.querySelector('flat-calendar');
  const month = content.querySelector('#header-month');
  const year = content.querySelector('#header-year');

  if (!calendar || !month || !year) {
    return;
  }
  updateView(calendar, year, month);
  content.querySelector('.previous-month').addEventListener('click', event => {
    let date = new Date(calendar.date);
    event.preventDefault();
    event.stopPropagation();
    date.setMonth(date.getMonth() - 1);
    calendar.date = date;
    updateView(calendar, year, month);
  });
  content.querySelector('.next-month').addEventListener('click', event => {
    let date = new Date(calendar.date);
    event.preventDefault();
    event.stopPropagation();
    date.setMonth(date.getMonth() + 1);
    calendar.date = date;
    updateView(calendar, year, month);
  });

  calendar.addEventListener('date', event => {
    const date = new DateOnly(event.detail);
    const datePath = date.toISOString().replace(/-/g, '/');
    const path = `${window.location.pathname}${datePath}/`;
    app.go(path);
  });
}

exports.init = init;
