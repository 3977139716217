/**
 * Return errors from response data
 * Returns Map of attribute => [errorMessages, ...]
 */
module.exports = function getErrors(response) {
  const errors = new Map();
  const re = /^"([a-zA-Z0-9_\-]+)" .+$/;

  if (!response.data) return errors;
  if (!response.data.status) return errors;
  if (response.data.status != 400) return errors;
  if (!response.data.errors && !response.data.errors.body) return errors;
  for (let error of response.data.errors.body) {
    let match = re.exec(error);
    if (!match) continue;
    let attr = match[1];
    if (!errors.has(attr)) {
      errors.set(attr, [error]);
    } else {
      errors.get(attr).push(error);
    }
  }
  return errors;
}
