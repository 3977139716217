const axios = require('axios');
const toggle = require('./toggle');

function init(content) {
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    columnDefs: [
      {
        field: 'initials', headerName: 'Inicjały',
        editable: true,
        maxWidth: 120,
        minWidth: 120
      },
      {
        field: 'name', headerName: 'Imię i nazwisko',
        editable: true,
        width: 240
      },
    ],
    rowData: null,
    onCellEditingStopped: event => {
      storeEmployee(event.data);
    },
    onGridReady: () => {
      fillGrid();
    }
  };
  grid.gridOptions = gridOptions;
  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });

  async function fillGrid() {
    try {
      const res = await axios.get('/api/inventory/employees');
      const rowData = [];
      res.data.forEach(employee => rowData.push({
        _id: employee._id,
        name: employee.name,
        initials: employee._id
      }));
      grid.api.setRowData(rowData);
      grid.api.sizeColumnsToFit();
    } catch (e) {
      console.error(e);
    }
  }

  async function storeEmployee(employee) {
    if (!employee) return;
    let newEmployee = false;
    const _id = employee._id || employee.initials;
    if (!_id) return;
    const body = {};
    try {
      const res = await axios.put(`/api/inventory/employees/${_id}`, {
        _id: employee.initials,
        name: employee.name 
      });
      const rowData = [];
      if (!employee._id) {
        newEmployee = true;
        rowData.push({_id: '', name: '', initials: ''});
      }
      grid.api.forEachNode(node => rowData.push({
        _id: node.data.initials,
        name: node.data.name,
        initials: node.data.initials
      }));
      grid.api.setRowData(rowData);
      if (newEmployee) {
        grid.api.setFocusedCell(1, 'name');
      }
    } catch (e) {
      console.error(e);
    }
  }
}


exports.init = init;
