const intmoney = require('../intmoney');

class CurrencyCellRenderer {
  init(params) {
    this.el = document.createElement('div');
    this.el.setAttribute('class', 'align-right');
    this.refresh(params);
  }

  getGui() {
    return this.el;
  }

  _renderNumber(value) {
    return `${new intmoney(value || 0)} zł`
  }

  _renderObject(obj) {
    let {currency, value} = obj;
    if (typeof currency !== 'string') {
      return '';
    }
    if (typeof value !== 'number') {
      return '';
    }
    if (currency.length == 1) {
      return `${currency} ${new intmoney(value || 0)}`;
    }
    return `${new intmoney(value || 0)} ${currency}`;
  }

  render(value) {
    switch (typeof value) {
      case 'object':
        return this._renderObject(value);
      case 'number':
        return this._renderNumber(value);
      default:
        return '';
    }
  }


  refresh(params) {
    this.el.innerText = this.render(params.value);
  }
}

module.exports = CurrencyCellRenderer;
