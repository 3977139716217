const _ = require('lodash');
const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const ProductTypeFilter = require('./grid/product-type-filter');
const updateSearch = require('./update-search');

function orderIdRenderer(params) {
  const {id, _id} = params.data;
  return `<a style="display: block;" href="/orders/${_id}/">${id}</a>`;
}

function progressBar(params) {
  let {issued, done, quantity, progress} = params.data;
  issued = issued || 0;
  done = done || 0;
  quantity = quantity || 0;

  let text = `
	<div
	  title="Wydano: ${ issued } Zrobiono: ${ done } Zamówiono: ${ quantity }"
	  style="width: 100%; border-radius: 0px;"
	  class="progress" >
		  <div
			class="progress-bar progress-bar-danger"
			role="progressbar"
			aria-valuemin="0"
			aria-valuenow="${issued}"
			aria-valuemax="${quantity}"
			style="width: ${progress.issued}%">
		  </div>
		  <div
			class="progress-bar progress-bar-primary"
			role="progressbar"
			aria-valuemin="0"
			aria-valuenow="${done}"
			aria-valuemax="${quantity}"
			style="width: ${progress.done}%">
		  </div>
	</div>
	`;
  return text;
}

function salesUnit(params) {
  const units = {
    'K': 'tys.',
    'pkg': 'op.',
  };
  return units[params.value] || params.value || '';
}

function getProductName(order) {
  let name = _.get(order, 'product.name', ''),
      index = _.get(order, 'product.index', '');
  return [name, index].join(' ').trim();
}

function makeRow(order) {
  const issued = _.get(order, 'quantity.issued', 0);
  const done = _.get(order, 'quantity.done', 0);
  const ordered = _.get(order, 'quantity.ordered', 0);
  const pieces = _.get(order, 'product.packaging.pieces', 0);
  const progress = {
    issued: Math.min(100, (issued / Math.max(ordered, done)) * 100),
    done: Math.min(100, ((done - issued) / Math.max(ordered, done)) * 100),
  };
  return {
    _id: order._id,
    id: order.id,
    date: new DateOnly(_.get(order, 'detail.created', new Date())).toISOString(),
    dueDate: new DateOnly(_.get(order, 'detail.due', new Date())).toISOString(),
    number: _.get(order, 'detail.number', ''),
    customer: _.get(order, 'detail.customer', ''),
    productName: getProductName(order),
    packages: _.get(order, 'product.packaging.packages', ''),
    unit: _.get(order, 'quantity.unit', ''),
    progress: progress,
    done: _.get(order, 'quantity.done', 0),
    issued: _.get(order, 'quantity.issued', 0),
    quantity: _.get(order, 'quantity.ordered', 0),
    pieces: pieces
  };
}

async function fillGrid(grid, range, search) {
  const startDate = new DateOnly(range.start).toISOString();
  const endDate = new DateOnly(range.end).toISOString();
  const params = {
    startDate,
    endDate,
    status: search.get('status') || 'active',
    type: search.get('type') || '',
  }
  if (params.status === 'all') {
    delete params.status;
  }
  if (!params.type) {
    delete params.type;
  }
  try {
    const res = await axios.get('/api/orders', {params});
    const rowData = res.data.map(order => makeRow(order));
    grid.api.setRowData(rowData);
  } catch (e) {
    console.error(e);
    grid.api.setRowData([]);
  } finally {
    grid.api.sizeColumnsToFit();
  }
}

async function postOrder() {
  const app = document.querySelector('single-page-app');
  const today = new DateOnly().toISOString();
  const body = {
    detail: {
      created: today,
      due: today,
      customer: '',
    },
    product: {
      packaging: {
        pieces: 1000,
      },
    },
    quantity: {
      unit: 'K',
    },
  };
  try {
    const res = await axios.post('/api/orders', body);
    app.go(`/orders/${res.data._id}/`);
  } catch (e) {
    console.error(e);
  }
}

function init(content) {
  const range = content.querySelector('date-range-select');
  const statusSelect = content.querySelector('select[name="status"]');
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    enableSorting: true,
    enableFilter: true,
    columnDefs: [
      {
        field: 'id', headerName: 'Id',
        cellClass: 'align-right',
        cellRenderer: orderIdRenderer,
        width: 80,
      },
      {
        field: 'number', headerName: 'Numer',
        cellClass: 'align-right',
        width: 70,
      },
      {
        field: 'date', headerName: 'Data',
        suppressFilter: true,
        width: 100,
      },
      {
        field: 'dueDate', headerName: 'Termin',
        suppressFilter: true,
        width: 100,
      },
      {
        field: 'customer', headerName: 'Klient',
        width: 125
      },
      {
        field: 'productName', headerName: 'Towar',
        filter: ProductTypeFilter,
      },
      {
        field: 'unit', headerName: 'j.s.',
        enableColumnMenu: false, enableHiding: false, enableFiltering: false,
        suppressFilter: true,
        width: 45,
        cellRenderer: salesUnit
      },
      {
        field: 'progress', headerName: 'Realizacja',
        cellRenderer: progressBar,
        suppressFilter: true,
        comparator: (a, b) => (a && a.issued || 0) - (b && b.issued || 0),
        width: 100
      }
    ],
    rowData: null,
    onGridReady: () => {
      const search = new URLSearchParams(window.location.search);
      statusSelect.value = search.get('status') || 'active';
      range.value = search.get('range') || '12-months';
      fillGrid(grid, range, search);
    },
  };
  grid.gridOptions = gridOptions;

  [range, statusSelect].forEach(select => {
    select.addEventListener('change', event => updateSearch(select));
  });

  const newOrder = content.querySelector('#new-order');
  if (newOrder) {
    newOrder.addEventListener('click', postOrder);
  }

  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
    if (newOrder) {
      newOrder.removeEventListener('click', postOrder);
    }
  });
}


exports.init = init;
