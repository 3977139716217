const _ = require('lodash');

function getPackageType(order) {
  const pt = _.get(order, 'product.packaging.packageType', 'Worek');
  return pt === 'Karton' ?  'kart.': 'worków';
}

function copyRow(row) {
  return row.map(col => _.extend({}, col));
}

function showOrderNumber(number) {
  if (!number) return '';
  return `   zam.${number}`;
}

module.exports = function pdfPalette(order, rows = 1) {
  const id          = _.get(order, 'id', '');
  const number      = _.get(order, 'detail.number', '');
  const customer    = _.get(order, 'detail.customer', '');
  const productName = _.get(order, 'product.name', '');
  const packages    = _.get(order, 'product.packaging.packages');
  const packageType = getPackageType(order);

  const idColumn = {
    text: id,
    margin: [2, 12, 2, 4],
  };
  const customerColumn = {
    text: [
      {text: customer, fontSize: 32, bold: true, alignment: 'left'},
      {text: showOrderNumber(number), fontSize: 14, bold: false, alignment: 'right'},
    ]
  };
  const productColumn = {
    text: productName,
    fontSize: 20,
    margin: [2, 8]
  };
  const quantityColumn = {
    text: `...... ${packageType} x ${packages}`,
    fontSize: 16,
    margin: [2, 20, 2, 50]
  };

  const idRow = [_.extend({}, idColumn), _.extend({}, idColumn)];
  const customerRow = [_.extend({}, customerColumn,), _.extend({}, customerColumn)];
  const nameRow = [_.extend({}, productColumn,), _.extend({},  productColumn)];
  const quantityRow = [_.extend({}, quantityColumn,), _.extend({}, quantityColumn)];


  const docDefinition =  {
    pageSize: 'A4',
    content: [],
    defaultStyle: {
      font: 'DejaVuSans',
      fontSize: 18,
      bold: true,
    },
    pageMargins: [4, 4],
  };

  // Add content several number of times
  for (let i = 0; i < rows; ++i) {
    docDefinition.content.push({
        table: {
          headerRows: 0,
          color: '#444',
          widths: ['50%', '50%'],
          body: [
            copyRow(idRow),
            copyRow(customerRow),
            copyRow(nameRow),
            copyRow(quantityRow),
          ]
        }
      });
    docDefinition.content.push({text: '', margin: [0, 8]});
  }

  return docDefinition;
}
