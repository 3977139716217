function getToggle() {
  return document.querySelector('#sidebar .toggle');
}

function addListener(callback) {
  let toggle = getToggle();
  if (!toggle) return;
  let toggleCallback = () => {
    setTimeout(callback, 500);
  };
  toggle.addEventListener('click', toggleCallback);
  return toggleCallback;
}

function removeListener(callback) {
  let toggle = getToggle();
  if (!toggle) return;
  toggle.removeEventListener('click', callback);
}

module.exports = {
  addListener,
  removeListener
};
