const axios = require('axios');
const menu = require('./menu');

function init(content) {
  const app = document.querySelector('single-page-app');
  const email = content.querySelector('input[name=email]');
  const password = content.querySelector('input[name=password]');
  const form = content.querySelector('form');

  if (!app) {
    throw new Error('Missing single-page-app element');
  }
  if (!email) {
    throw new Error('Missing email field');
  }
  if (!password) {
    throw new Error('Missing password field');
  }
  if (!form) {
    throw new Error('Missing form button');
  }

  form.addEventListener('submit', onSubmit);

  content.addEventListener('unload', () => {
    form.removeEventListener('submit', onSubmit);
  });

  setTimeout(() => email.focus(), 500);

  async function onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    try {
      let path;
      const params = new URLSearchParams(window.location.search);
      const loc = params.get('location');
      const baseUrl = `${window.location.protocol}\/\/${window.location.host}`;
      const response = await axios.post('/auth/local', {
        email: email.value,
        password: password.value
      });
      if (!response.data || !response.data.token) {
        throw new Error('Did not receive authentication token');
      }
      localStorage.setItem('token', response.data.token);
      if (loc && loc.startsWith(baseUrl)) {
        // Strip ${protocol}//${host} from location path
        path = loc.substring(baseUrl.length);
      } else {
        path = '/';
      }
      menu();
      app.go(path);
    } catch (e) {
      console.error(e);
    }
  }
}

module.exports.init = init;
