const axios = require('axios');

async function init(content) {
  const app = document.querySelector('single-page-app');
  const parts = window.location.pathname.split('/');
  let id = parts.pop();
  console.log(id);
  while (!id) {
    console.log(id);
    id = parts.pop();
  }

  try {
    const res = await axios.get('/api/orders', {params: {orderId: id}})
    if (res.data.length == 0) {
      app.go('/orders/');
      return;
    }
    app.go(`/orders/${res.data[0]._id}/`);
  } catch (e) {
    console.error(e);
    app.go('/orders/');
  }

}

module.exports.init = init;
