const axios = require('axios');

module.exports.init = async function init(content) {
  const usersEl = content.querySelector('#users');
  try {
    response = await axios.get('/api/users');
    for (let user of response.data) {
      let li = document.createElement('li', {is: 'li-user'});
      
      li.href = `/accounts/${user._id}/`;
      li.username = user.name;
      li.email = user.email;
      usersEl.appendChild(li);
    }
  } catch (e) {
    console.log(e);
  }
}
