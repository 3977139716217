const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const ProductTypeFilter = require('./grid/product-type-filter');
const ProductLinkCellRenderer = require('./grid/product-link-cell-renderer');
const NumberCellRenderer = require('./grid/number-cell-renderer');

async function fillGrid(grid, range) {
  const start = new DateOnly(range.start).toISOString();
  const end = new DateOnly(range.end).toISOString();
  let snapshotDate = new Date(range.start);
  snapshotDate.setDate(snapshotDate.getDate() - 1);
  snapshotDate = new DateOnly(snapshotDate);

  const snapshotPath = snapshotDate.toISOString().replace(/-/g, '/');
  const params = {
    params: {startDate: start, endDate: end}
  };
  let rowData;

  try {
    const [products, snapshots, additions, issues] = await Promise.all([
      axios.get('/api/products'),
      axios.get(`/api/inventory/snapshot/${snapshotPath}`),
      axios.get('/api/inventory/additions', params),
      axios.get('/api/inventory/issues', params)
    ]);
    const rowMap = new Map();

    rowData = products.data.map(product => {
      row = {
        productId: product._id,
        name: product.name,
        begin: 0,
        additions: 0,
        issues: 0,
        end: 0
      };
      rowMap.set(product._id, row);
      return row;
    });

    snapshots.data.forEach(snapshot => {
      const row = rowMap.get(snapshot.productId);
      if (!row) return;
      row.begin += snapshot.items;
    });

    additions.data.forEach(addition => {
      const row = rowMap.get(addition.productId);
      if (!row) return;
      row.additions += addition.quantity;
    });

    issues.data.forEach(issue => {
      const row = rowMap.get(issue.productId);
      if (!row) return;
      row.issues += issue.quantity;
    });

    rowData.forEach(row => row.end = row.begin + row.additions - row.issues);
  } catch (e) {
    rowData = [];
    console.error(e);
  } finally {
    grid.api.setRowData(rowData);
    grid.api.sizeColumnsToFit();
  }
}

function init(content) {
  const app = document.querySelector('single-page-app');
  const range = content.querySelector('date-range-select');
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        field: 'name', headerName: 'Produkt', width: 225,
        filter: ProductTypeFilter,
        cellRenderer: ProductLinkCellRenderer
      },
      {
        field: 'begin', headerName: 'Stan początkowy', width: 145,
        cellRenderer: NumberCellRenderer,
        cellClass: 'align-right'
      },
      {
        field: 'additions', headerName: 'Produkcja', width: 145,
        cellRenderer: NumberCellRenderer,
        cellClass: 'align-right'
      },
      {
        field: 'issues', headerName: 'Sprzedaż', width: 145,
        cellRenderer: NumberCellRenderer,
        cellClass: 'align-right'
      },
      {
        field: 'end', headerName: 'Stan końcowy', width: 145,
        cellRenderer: NumberCellRenderer,
        cellClass: 'align-right'
      }
    ],
    rowData: null,
    onGridReady: () => {
      const search = new URLSearchParams(window.location.search);
      range.value = search.get('dateRange') || 'week';

      fillGrid(grid, range);

      range.addEventListener('change', event => {
        search.set('dateRange', range.value);
        app.go(window.location.pathname, {search});
      });
    }
  };
  grid.gridOptions = gridOptions;
  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });
}

exports.init = init;
