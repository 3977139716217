const axios = require('axios');
const _ = require('lodash');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const ProductTypeFilter = require('./grid/product-type-filter');
const ProductLinkRenderer = require('./grid/product-link-cell-renderer');
const NumberCellRenderer = require('./grid/number-cell-renderer');
const CurrencyCellRenderer = require('./grid/currency-cell-renderer');
const getSnapshotUrl = require('./util/get-snapshot-url');
const getOrderSnapshotUrl = require('./util/get-alt-order-snapshot-url');

function total(grid, column) {
  let t = 0;
  grid.api.forEachNodeAfterFilter(node => {
    t += node.data[column].value || 0;
  })
  return t;
}

module.exports.init = function init(content) {
  const [_1_, _2_, _3_, year, month, day, _4_] = location.pathname.split('/');
  const grid = content.querySelector('#order-value-grid');
  content.querySelector('#calendar').innerHTML = `&nbsp;${day}/${month}/${year}`;
  grid.setAttribute('title', `Wartość zamówień z dnia ${day}/${month}/${year}`);
  grid.gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        headerName: 'Produkt',
        field: 'name',
        filter: ProductTypeFilter,
        cellRenderer: ProductLinkRenderer,
        pinnedRowCellRenderer: function() { return ''; },
        editable: false
      },
      {
        headerName: 'Zam.',
        field: 'orderId',
        suppressFilter: true,
        width: 80,
        cellClass: 'align-right',
        pinnedRowCellRenderer: function() { return ''; },
        editable: false,
      },
      {
        headerName: 'Stan',
        field: 'items',
        suppressFilter: true,
        width: 90,
        editable: false,
        cellClass: 'align-right',
        cellRenderer: NumberCellRenderer,
        pinnedRowCellRenderer: function() { return ''; },
      },
      {
        headerName: 'Cena jedn.',
        field: 'price',
        suppressFilter: true,
        width: 100,
        editable: false,
        cellRenderer: CurrencyCellRenderer,
        pinnedRowCellRenderer: function() { return 'Razem'; },
        cellClass: 'align-right',
      },
      {
        headerName: 'Wartość',
        field: 'value',
        suppressFilter: true,
        width: 140,
        editable: false,
        cellRenderer: CurrencyCellRenderer,
        cellClass: 'align-right',
      }
    ],
    doesExternalFilterPass: node => {
      if (!node.data) { return false; }
      return node.data.snapshot || node.data.stocktaking;
    },
    rowData: null,
    onGridReady: async () => {
      let rowData = [];
      const date = `${year}-${month}-${day}`;
      const orderSnapshotUrl = getOrderSnapshotUrl(date);
      const snapshotMap = new Map();
      const snapshots = await axios.get(orderSnapshotUrl);
      const map = new Map();
      snapshots.data.forEach(snapshot => {
        snapshot.orderId = snapshot._id
        map.set(snapshot._id, snapshot);
      });
      
      const orders = await axios.get('/api/orders/', {params: {
        orderIds: Array.from(map.keys()).join(',')}
      });
      orders.data.forEach(order => {
        let snapshot = map.get(order.id);
        let currency = _.get(order, 'product.currency', 'zł');
        let exchangeRate = 1
        if (currency !== 'zł') {
          exchangeRate = _.get(order, 'product.exchangeRate', 1);
        }
        snapshot.price = {currency: currency, value: (order.product.price || 0) * 100};
        snapshot.value = {
          currency: 'zł',
          value: (order.product.price || 0) * exchangeRate * 100 * (snapshot.items || 0)
        };
      });
      snapshots.data.sort((a, b) => {
        if (a._id > b._id) {
          return -1;
        } else if (a._id == b._id) {
          return 0;
        } else {
          return 1;
        }
      })
      grid.api.setRowData(snapshots.data);
      grid.api.sizeColumnsToFit();
      grid.api.setPinnedBottomRowData([{
        orderId: '',
        name: '',
        snapshot: '',
        price: '',
        value: total(grid, 'value')
      }])
    }
  };
  const toggleHandler = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleHandler);
  });
}
