const toggle = require('./toggle');
const axios = require('axios');

async function setupChart(chart) {
  const dataLabels = [];
  const months = 12;
  const borderWidth = 2;
  const options = {
    type: 'bar',
    data: {
      datasets: [
        {
          data: [],
          label: 'Produkty',
          backgroundColor: 'rgba(234,241,245,0.7)',
          borderColor: 'rgba(151,187,205,1)',
          borderWidth,
        },
        {
          data: [],
          label: 'Zamówienia',
          backgroundColor: 'rgba(248,248,248,0.7)',
          borderColor: 'rgba(220,220,220,1)',
          borderWidth,
        },
        {
          type: 'line',
          fill: false,
          data: [],
          label: 'Wytłaczarki',
          backgroundColor: 'rgba(247,70,74,1)',
          borderColor: 'rgba(247,70,74,1)',
        },
        {
          type: 'bar',
          data: [],
          label: 'Wykrojniki',
          backgroundColor: 'rgba(218,242,242,0.7)',
          borderColor: 'rgba(70,191,189,1)',
          borderWidth,
        },
      ],
      labels: [],
    },
    options: {
      responsive: false,
      scales: {
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            stacked: true,
            display: true,
            position: 'left',
          }
        ],
        xAxes: [
          {
            stacked: true,
          }
        ]
      }
    },
  };

  try {
    const date = new Date();
    const params = { params: { months } };
    const punchParams = { params: { months, punch: true } };
    const [
      productWeight,
      ordersWeight,
      extrudersWeight,
      productPunchWeight,
      ordersPunchWeight,
    ] = await Promise.all([
      axios.get('/api/inventory/additions/weight', params),
      axios.get('/api/orders/weight', params),
      axios.get('/api/extruders/weight', params),
      axios.get('/api/inventory/additions/weight', punchParams),
      axios.get('/api/orders/weight', punchParams)
    ]);

    date.setHours(0, 0, 0, 0);
    date.setUTCDate(1);
    for (let i = months; i >= 0; --i) {
      options.data.labels.unshift(date.toISOString().substring(0, 7));
      dataLabels.unshift(date.getFullYear() * 100 + date.getMonth());
      date.setMonth(date.getMonth() - 1);
    }
    dataLabels.forEach(label => {
      const punchWeight = (productPunchWeight.data[label] || 0) +
                          (ordersPunchWeight.data[label]  || 0);

      options.data.datasets[0].data.push(productWeight.data[label] || 0);
      options.data.datasets[1].data.push(ordersWeight.data[label] || 0);
      options.data.datasets[2].data.push(extrudersWeight.data[label] || 0);
      options.data.datasets[3].data.push(punchWeight);
    });
  } catch (e) {
    console.error(e);
  } finally {
    chart.options = options;
  }
}

module.exports.init = function init(content) {
  const chart = content.querySelector('chart-js');

  setupChart(chart);
}
