const axios = require('axios');
const toggle = require('./toggle');
const NumberCellRenderer = require('./grid/number-cell-renderer');
const ProductLinkCellRenderer = require('./grid/product-link-cell-renderer');
const ProductTypeFilter = require('./grid/product-type-filter');

function init(content) {
  const [productBadge, orderBadge, todoBadge] = content.querySelectorAll('.badge');
  const grid = content.querySelector('ag-grid');
  const gridOptions = {
    enableSorting: true,
    enableFilter: true,
    columnDefs: [
      {
        headerName: 'Nazwa',
        field: 'name',
        width: 225,
        filter: ProductTypeFilter,
        cellRenderer: ProductLinkCellRenderer
      },
      {
        headerName: 'Ilosć w op.',
        children: [
          {
            headerName: 'Do zrobienia',
            cellRenderer: NumberCellRenderer,
            field: 'todo',
            suppressFilter: true
          },
          {
            headerName: 'Na magazynie',
            cellRenderer: NumberCellRenderer,
            field: 'state',
            suppressFilter: true
          }
        ]
      },
      {
        headerName: 'Ilosć w tys.',
        children: [
          {
            headerName: 'Do zrobienia',
            attribute: 'todo_k',
            sort: 'desc',
            cellRenderer: NumberCellRenderer,
            valueGetter: function(params) {
              return (params.data.pieces * params.data.todo) / 1000;
            },
            suppressFilter: true
          },
          {
            headerName: 'Na magazynie',
            attribute: 'state_k',
            cellRenderer: NumberCellRenderer,
            valueGetter: function(params) {
              return (params.data.pieces * params.data.state) / 1000;
            },
            suppressFilter: true
          }
        ]

      }
    ],
    rowData: null,
    onGridReady: async () => {
      try {
        const response = await axios.get('/api/inventory');
        grid.api.setRowData(response.data);
      } catch (e) {
        console.error(e);
        grid.api.setRowData([]);
      } finally {
        grid.api.sizeColumnsToFit();
      }
      updateBadges();
    },
    onFilterChanged: updateBadges,
  };
  grid.gridOptions = gridOptions;
  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleCallback);
  });

  function updateBadges() {
    let products = 0;
    let orders = 0;
    let todo = 0;
    grid.api.forEachNodeAfterFilter(node => {
      todo += (node.data.pieces || 0) * (node.data.state || 0);
      if (node.data.productId) {
        ++products;
      } else if (node.data.orderId) {
        ++orders;
      }
    });
    productBadge.innerText = products;
    orderBadge.innerText = orders;
    todoBadge.innerText = Math.round(todo / 1000);
  }
}

exports.init = init;
