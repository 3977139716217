const axios = require('axios');
const DateOnly = require('dateonly');
const intmoney = require('./intmoney');
const fillDatalist = require('./fill-datalist');

function findParentWithClass(className, child) {
  let parent = child.parentElement;
  while (parent && !parent.classList.contains(className)) {
    parent = parent.parentElement;
  }
  return parent;
}

function pln(value) {
  return `${new intmoney(value)} zł`;
}

function getReceiptId(pathname) {
  return pathname.split('/')[3];
}

async function getCustomerData(customerName) {
  const response = await axios.get(`/api/customers/${customerName}`);
  return response.data;
}

function getPriceGroupId(customer) {
  if (!customer) return 9;
  return customer.priceGroupId || 9;
}

function findOrderIdInput(content) {
  return content.querySelector('input[name="orderId"]');
}

function findProductNameInput(content) {
  return content.querySelector('input[name="product-name"]');
}

function findQuantityInput(content) {
  return content.querySelector('input[name="quantity"]');
}

function findPriceInput(content) {
  return content.querySelector('input[name="price"]');
}

function findRebateInput(content) {
  return content.querySelector('input[name="rebate"]');
}

function findValueElement(content) {
  return content.querySelector('.value');
}

function findTrashButton(content) {
  return content.querySelector('button.fa-trash');
}

function addEntry(ul, template, entry) {
  const {
    orderId,
    issue,
    price,
    productName,
    quantity,
    rebate,
    value
  } = entry;

  const li            = document.createElement('li');
  const content       = template.content.cloneNode(true);
  const orderIdEl     = findOrderIdInput(content);
  const productNameEl = findProductNameInput(content);
  const quantityEl    = findQuantityInput(content)
  const priceEl       = findPriceInput(content);
  const rebateEl      = findRebateInput(content);
  const valueEl       = findValueElement(content);
  const trashButton   = findTrashButton(content);

  orderIdEl.value = orderId;
  productNameEl.value = productName || '';
  quantityEl.value = quantity;
  priceEl.value = new intmoney(price) || '';
  rebateEl.value = rebate;
  valueEl.innerText = pln(value);
  valueEl.dataset.value = value;

  li.appendChild(content);
  ul.appendChild(li);
}

function changeValue(row) {
  const quantity = findQuantityInput(row).value;
  const price = findPriceInput(row).value;
  const rebate = findRebateInput(row).value;
  const valueEl = findValueElement(row);
  let value = quantity * intmoney.parse(price) * (100 - rebate) / 100;
  valueEl.dataset.value = value;
  valueEl.innerText = pln(value);
}

async function changeProductDetails(row, productsDatalist, priceGroupId) {
  const productName = findProductNameInput(row).value;
  const priceEl = findPriceInput(row);
  const orderIdEl = findOrderIdInput(row);
  orderIdEl.value = '';
  let option = null;
  for (option of productsDatalist.querySelectorAll('option')) {
    if (option.value == productName) break;
  }
  if (!option) return;
  try {
    const response = await axios.get(`/api/products/${option.dataset.id}`);
    const product = response.data;
    if (!product.prices) return;
    const price = product.prices[priceGroupId];
    if (typeof price !== 'number') return;
    priceEl.value = new intmoney(price).toString();
    changeValue(row);
  } catch (e) {
    console.error(e);
  }
}

async function getOrder(orderId) {
  const params = { orderId };
  let order = null;
  try {
    const response = await axios.get('/api/orders', {params});
    if (response.data.length != 1) return;
    order = response.data[0];
  } catch (e) {
    console.error(e);
  }
  return order;
}

function changeOrderDetails(row, order) {
  const productNameEl = findProductNameInput(row);
  const priceEl = findPriceInput(row);
  if (!order && !order.product) return;
  if (order.product.name) {
    productNameEl.value = order.product.name;
  }
  if (order.product.price) {
    priceEl.value = new intmoney(intmoney.parse(order.product.price)).toString();
  }
}


function importEntries(receipt, entryRows) {
  for (let row of entryRows) {
    const orderIdEl = findOrderIdInput(row);
    const productNameEl = findProductNameInput(row);
    const quantityEl = findQuantityInput(row);
    const priceEl = findPriceInput(row);
    const rebateEl = findRebateInput(row);
    const valueEl = findValueElement(row);

    const orderId = parseInt(orderIdEl.value) || null;
    const productName = productNameEl.value;
    // Parse quantity to either int, or float, depending on input
    const quantity = (quantityEl.value * 1) || 0;
    const price = intmoney.parse(priceEl.value) || 0;
    const rebate = parseFloat(rebateEl.value) || 0;
    const value = parseInt(valueEl.dataset.value) || 0;

    receipt.entries.push({
      orderId,
      productName,
      quantity,
      price,
      rebate,
      value
    });
  }
}

module.exports.init = async function init(content) {
  let saved = true;
  const _id = getReceiptId(location.pathname);
  const customerInput = content.querySelector('input[name="customer"]');
  const dateInput = content.querySelector('input[name="date"]');
  const priceGroupSelect = content.querySelector('select[name="price-group"]');
  const entriesUl = content.querySelector('#entries-list');
  const valueEl = content.querySelector('#value');
  const saldoEl = content.querySelector('#saldo');
  const paidEl = content.querySelector('input[name="paid"]');
  const template = content.querySelector('#entry-template');
  const newEntryButton = content.querySelector('#new-entry');
  const saveButton = content.querySelector('#save');
  const datalist = content.querySelector('#products');
  let customer;

  try {
    fillDatalist(datalist, '/api/products/');
    const response = await axios.get(`/api/receipts/${_id}`);
    const receipt = response.data;
    customer = await getCustomerData(receipt.customer);
    customerInput.value = receipt.customer;
    dateInput.value = new DateOnly(receipt.date).toISOString();
    priceGroupSelect.value = getPriceGroupId(customer);
    valueEl.innerText = pln(receipt.value);
    valueEl.dataset.value = receipt.value;
    saldoEl.innerText = pln(customer.saldo);
    saldoEl.dataset.oldCustomer = receipt.customer;
    saldoEl.dataset.oldValue = receipt.value || 0;
    saldoEl.dataset.oldPaid = receipt.paid || 0;
    saldoEl.dataset.oldSaldo = customer.saldo;
    paidEl.value = new intmoney(receipt.paid).toString();
    for (let entry of receipt.entries) {
      addEntry(entriesUl, template, entry);
    }
    setTitle();
  } catch (e) {
    console.error(e);
  }

  customerInput.addEventListener('change', async event => {
    const customer = await getCustomerData(event.target.value);
    if (event.target.value !== saldoEl.dataset.oldCustomer) {
      customer.saldo += parseInt(saldoEl.dataset.oldPaid) - parseInt(saldoEl.dataset.oldValue);
    }
    priceGroupSelect.value = getPriceGroupId(customer);
    saldoEl.dataset.oldSaldo = customer.saldo;
    setTitle();
    changeSaldo();
    setUnsaved();
  });

  priceGroupSelect.addEventListener('change', async event => {
    customer.priceGroupId = parseInt(event.target.value) || 9;
    try {
      await axios.put(`/api/customers/${customer.name}`, customer);
    } catch (e) {
      console.error(e);
    }
    console.log('priceGroup', customer, event.target.value);
  });

  dateInput.addEventListener('change', setUnsaved);

  content.addEventListener('change', async event => {
    if (event.target.tagName !== 'INPUT') return;
    const row = findParentWithClass('row', event.target)
    if (!row) return;
    if (['quantity', 'price', 'rebate'].includes(event.target.name)) {
      changeValue(row);
    } else if (event.target.name === 'product-name') {
      await changeProductDetails(row, datalist, priceGroupSelect.value);
    } else if (event.target.name === 'orderId') {
      const order = await getOrder(event.target.value);
      changeOrderDetails(row, order);
    }

    changeTotalValue();
    changeSaldo();
    setUnsaved();
  });

  content.addEventListener('click', event => {
    if (event.target.tagName !== 'BUTTON') return;
    if (!event.target.classList.contains('fa-trash')) return;
    const row = findParentWithClass('row', event.target);
    if (!row) return;
    const li = row.parentElement;
    if (!li || li.tagName !== 'LI') return;
    li.remove();
    changeTotalValue();
    changeSaldo();
    setUnsaved();
  });

  saveButton.addEventListener('click', async event => {
    const customer = customerInput.value;
    const date = new DateOnly(dateInput.value).valueOf();
    const value = parseInt(valueEl.dataset.value);
    const paid = intmoney.parse(paidEl.value);
    const receipt = {
      customer,
      date,
      value,
      paid,
      entries: []
    };
    importEntries(receipt, content.querySelectorAll('#entries-list .entry.row'))
    try {
      await axios.put(`/api/receipts/${_id}`, receipt);
      setSaved();
    } catch (e) {
      console.error(e);
    }
  });

  newEntryButton.addEventListener('click', () => addEntry(entriesUl, template, {}));

  function changeTotalValue() {
    let totalValue = 0;
    for (let valueEl of content.querySelectorAll('#entries-list .value')) {
      totalValue += parseInt(valueEl.dataset.value) || 0;
    }
    valueEl.dataset.value = totalValue;
    valueEl.innerText = pln(totalValue);
  }

  function changeSaldo() {
    const totalValue = parseInt(valueEl.dataset.value) || 0;
    const paid = intmoney.parse(paidEl.value) || 0;
    const oldSaldo = parseInt(saldoEl.dataset.oldSaldo);
    const oldValue = parseInt(saldoEl.dataset.oldValue);
    const oldPaid = parseInt(saldoEl.dataset.oldPaid);

    const saldo = oldSaldo + oldValue - oldPaid - totalValue + paid;
    saldoEl.innerText = pln(saldo);
  }

  function setUnsaved() {
    if (!saved) return;
    saved = false;
    saveButton.classList.remove('disabled');
    saveButton.classList.remove('fa-check');
    saveButton.innerText = 'Zapisz';
  }

  function setSaved() {
    if (saved) return;
    saved = true;
    saveButton.classList.add('disabled');
    saveButton.classList.add('fa-check');
    saveButton.innerText = 'Zapisano';
  }

  function setTitle() {
    document.title = `Rachunek ${customer.name} - ${dateInput.value}`
  }
}
