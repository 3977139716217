const _ = require('lodash');
const axios = require('axios');
const DateOnly = require('dateonly');

const pdfOrder = require('./pdf-order');
const pdfPalette = require('./pdf-palette');
const pdfRoller = require('./pdf-roller');
const orderInventoryEntries = require('./order-inventory-entries');

function getId(pathname) {
  const parts = pathname.split('/');
  let part = parts.pop();
  while(!part) {
    part = parts.pop();
  }
  return part;
}

async function populateCustomersDataList(datalist) {
  if (!datalist) return;
  try {
    const res = await axios.get('/api/orders/customers');
    res.data.forEach(customer => {
      let option = document.createElement('option');
      option.innerText = customer;
      datalist.appendChild(option);
    });
  } catch (e) {
    console.error(e);
  }
}

async function populateProductsDataList(datalist, customer) {
  if (!datalist || !customer) return;
  try {
    const set = new Set();
    const res = await axios.get('/api/orders', {
      params: {customer}
    });
    datalist.innerHTML = '';
    const options = res.data.forEach(order => {
      const name = _.get(order, 'product.name');
      if (!name) return;
      if (set.has(name)) return;
      const option = document.createElement('option');
      option.innerText = _.get(order, 'product.name');
      option.dataset.id = order._id;
      datalist.appendChild(option);
      set.add(name);
    });
  } catch (e) {
    console.error(e);
  }
}

function updateTitle(content, order) {
  const id = _.get(order, 'id', '');
  const customer = _.get(order, 'detail.customer', '');
  const productName = _.get(order, 'product.name', '');
  const title = `Zamówienie ${customer} ${productName} nr. ${id}`;
  document.title = title;
  content.querySelector('#order-title').innerText = title;
}

function getOrderedPieces(order) {
  return _.get(order, 'quantity.ordered', 0) *
         _.get(order, 'product.packaging.pieces', 0);
}

function getRollers(order) {
  const ordered = _.get(order, 'quantity.ordered', 0);
  const pieces  = _.get(order, 'product.packaging.pieces', 0);
  const tracks  = _.get(order, 'product.film.tracks', 1);
  const revs    = _.get(order, 'product.film.revs', 1000);
  const height  = _.get(order, 'product.size.height', 0);

  return (parseFloat(ordered * pieces) / 1000) /
         (parseFloat(tracks * revs)  / parseFloat(10 * height));
}

function getPackageWeight(order) {
  const width     = _.get(order, 'product.size.width', 0);
  const height    = _.get(order, 'product.size.height', 0);
  const fold      = _.get(order, 'product.size.fold', 0);
  const density   = _.get(order, 'product.film.density', 0);
  const thickness = _.get(order, 'product.film.thickness', 0);
  const punch     = _.get(order, 'product.film.punch', 0);
  const pieces    = _.get(order, 'product.packaging.pieces', 0);

  return parseFloat(width + 2 * fold) * 2 * height *
         parseFloat(thickness * density * pieces) *
         (parseFloat(100 - punch) / 100) / 10000000;
}

function calculateAttributes(content, order) {
  const packageWeight = content.querySelector('#package-weight');
  const pricePerPkg   = content.querySelector('#price-per-kg');
  const orderedPieces = content.querySelector('#ordered-pieces');
  const rollers       = content.querySelector('#rollers');
  const price         = content.querySelector('#price');

  packageWeight.value = getPackageWeight(order).toFixed(2);
  orderedPieces.value = getOrderedPieces(order);
  rollers.value       = getRollers(order).toFixed(2);
  pricePerPkg.value   = (price.value / packageWeight.value).toFixed(2);
}


function setupContent(content, order) {
  // Scope variables to store responsive inputs
  let unit, pieces, currency, exchangeRate;
  const inputs = content.
    querySelectorAll('input[data-attribute],select[data-attribute]');
  const paletteRadios = content.querySelectorAll('#palette input[name="rows"]');
  const customer = _.get(order, 'detail.customer');
  const customersDataList = content.querySelector('datalist#customers');
  const productsDataList = content.querySelector('datalist#products');
  const additionsTab = content.querySelector('#additions');
  const issuesTab = content.querySelector('#issues');
  const template = content.querySelector('#inventory-form');
  const doneInput = content.querySelector('#done');
  const issuedInput = content.querySelector('#issued');
  const printPdfMake = content.querySelector('#print pdf-make');
  const palettePdfMake = content.querySelector('#palette pdf-make');
  const rollerPdfMake = content.querySelector('#rollers pdf-make');

  populateCustomersDataList(customersDataList);
  populateProductsDataList(productsDataList, customer);
  updateTitle(content, order);
  setupInputs(inputs, order);
  calculateAttributes(content, order);
  inputs.forEach(input => input.addEventListener('change', patchOrder));
  paletteRadios.forEach(radio => radio.addEventListener('change', onPaletteRadioChange));
  orderInventoryEntries.init({
    container: additionsTab,
    order,
    template,
    collection: 'additions',
    who: '',
  });
  additionsTab.addEventListener('quantity', event => doneInput.value = event.detail);
  orderInventoryEntries.init({
    container: issuesTab,
    order,
    template,
    collection: 'issues',
    who: _.get(order, 'detail.customer', ''),
  });
  issuesTab.addEventListener('quantity', event => issuedInput.value = event.detail);

  const copyButton = content.querySelector('.icons .fa-copy');
  if (copyButton) {
    copyButton.addEventListener('click', onCopyOrder);
  }
  const giveAllButton = content.querySelector('.give-all');
  if (giveAllButton) {
    giveAllButton.addEventListener('click', onGiveAll);
  }

  const deleteButton = content.querySelector('.icons .fa-trash');
  if (deleteButton) {
    if ((_.get(order, 'quantity.done', 0) + _.get(order, 'quantity.issued', 0)) > 0) {
      deleteButton.setAttribute('disabled', 'disabled');
    }
    deleteButton.addEventListener('click', onDeleteOrder);
  }

  const customerInput = content.querySelector('#customer');
  if (customerInput) {
    customerInput.addEventListener('change', onCustomerInputChanged);
  }

  const productInput = content.querySelector('#product-name');
  if (productInput) {
    productInput.addEventListener('change', onProductInputChanged);
  }

  const printTabButton = content.querySelector('tabbed-content a[href="#print"]');
  if (printTabButton) {
    printTabButton.addEventListener('click', onShowPrintTab);
  }
  const paletteTabButton = content.querySelector('tabbed-content a[href="#palette"]');
  if (paletteTabButton) {
    paletteTabButton.addEventListener('click', onShowPaletteTab);
  }
  const rollerTabButton = content.querySelector('tabbed-content a[href="#rollers"]');
  if (rollerTabButton) {
    rollerTabButton.addEventListener('click', onShowRollerTab);
  }

  content.addEventListener('unload', () => {
    if (copyButton) {
      copyButton.removeEventListener('click', onCopyOrder);
    }
    if (deleteButton) {
      deleteButton.removeEventListener('click', onDeleteOrder);
    }
    if (productInput) {
      productInput.removeEventListener('change', onProductInputChanged);
    }
    if (customerInput) {
      customerInput.removeEventListener('change', onCustomerInputChanged);
    }
    if (printTabButton) {
      printTabButton.removeEventListener('click', onShowPrintTab);
    }
    if (paletteTabButton) {
      paletteTabButton.removeEventListener('click', onShowPaletteTab);
    }
    if (rollerTabButton) {
      rollerTabButton.removeEventListener('click', onShowRollerTab);
    }

    inputs.forEach(input => input.removeEventListener('change', patchOrder));
  });

  async function onCopyOrder(event) {
    const body = {
      product: {
        name: _.get(order, 'product.name', ''),
        index: _.get(order, 'product.index', ''),
        price: _.get(order, 'product.price', ''),
        currency: _.get(order, 'product.currency', ''),
        exchangeRate: _.get(order, 'product.exchangeRate', ''),
        size: {
          width: _.get(order, 'product.size.width', ''),
          height: _.get(order, 'product.size.height', ''),
          fold: _.get(order, 'product.size.fold', ''),
        },
        packaging: {
          block: _.get(order, 'product.packaging.block', ''),
          control: _.get(order, 'product.packaging.control', ''),
          pieces: _.get(order, 'product.packaging.pieces', ''),
          packages: _.get(order, 'product.packaging.packages', ''),
          instructions: _.get(order, 'product.packaging.instructions', ''),
          packageType: _.get(order, 'product.packaging.packageType', ''),
          palette: _.get(order, 'product.packaging.palette', ''),
        },
        film: {
          name: _.get(order, 'product.film.name'),
          density: _.get(order, 'product.film.density', ''),
          thickness: _.get(order, 'product.film.thickness', ''),
          punch: _.get(order, 'product.film.punch', ''),
          revs: _.get(order, 'product.film.revs', ''),
          tracks: _.get(order, 'product.film.tracks', ''),
          ionization: _.get(order, 'product.film.ionization', ''),
        },
        print: {
          film: _.get(order, 'product.print.film', ''),
          front: _.get(order, 'product.print.front', ['', '', '', '']),
          back: _.get(order, 'product.print.back', ['', '', '', '']),
        }
      },
      detail: {
        customer: _.get(order, 'detail.customer', ''),
        number: _.get(order, 'detail.number', ''),
        created: new DateOnly().toISOString(),
        due: new DateOnly().toISOString(),
      },
      quantity: {
        unit: _.get(order, 'quantity.unit'),
      },
    };

    try {
      const res = await axios.post('/api/orders', body);
      const _id = res.data._id;
      const app = document.querySelector('single-page-app');

      app.go(`/orders/${_id}/`);
    } catch (e) {
      console.error(e);
    }
  }
  
  async function onGiveAll(event) {
    event.preventDefault();
    event.stopPropagation();
    const orderId = content.querySelector('#id').value;
    const params = { params: {orderId} }
    const form = event.target.closest('form');
    if (!form) return;
    const quantity = form.querySelector('input[name=quantity]');
    console.log(quantity);
    if (!quantity) return;
    const button = form.querySelector('button.fa-plus');
    console.log(button);
    if (!button) return;
    try {
      const additions = await axios.get('/api/inventory/additions', params);
      const issues = await axios.get('/api/inventory/issues', params);
      additions.data.forEach(a => console.log(a.quantity))
      let total = additions.data.reduce((s, i) => s + (i.quantity || 0), 0) - 
                  issues.data.reduce((s, i) => s + (i.quantity || 0), 0);
      console.log(total);
      quantity.value = total;
      button.click();
    } catch (err) {
      console.log(err);
    }
  }

  async function onDeleteOrder(event) {
    const _id = getId(window.location.pathname);
    if (!confirm('Czy na pewno usunąć zamówienie?')) return;
    try {
      await axios.delete(`/api/orders/${_id}`);
      const app = document.querySelector('single-page-app');
      app.go('/orders/');
    } catch (e) {
      console.error(e);
    }

  }

  function onCustomerInputChanged(event) {
    populateProductsDataList(productsDataList, event.target.value);
  }

  async function onProductInputChanged(event) {
    if (!event.target && !event.target.value) return;

    const value = event.target.value;
    let _id = null;
    for (let option of productsDataList.children) {
      if (option.value === value) {
        _id = option.dataset.id;
        break;
      }
    }
    if (!_id) return;
    try {
      const res = await axios.get(`/api/orders/${_id}`);
      if (!res.data.product) return;
      _.extend(order.product, res.data.product);
      try {
        order.quantity.unit = res.data.quantity.unit;
      } catch (e) {
        // Ignore
      }

      let {detail, product, quantity} = order;

      _id = getId(window.location.pathname);

      await axios.put(`/api/orders/${_id}`, {detail, product, quantity});
      setupInputs(inputs, order);
    } catch (e) {
      console.error(e);
    }
  }

  function setupInputs(inputs, order) {
    inputs.forEach(input => {
      if (input.type === 'date') {
        input.value = new DateOnly(_.get(order, input.dataset.attribute, ''))
          .toISOString();
      } else {
        input.value = _.get(order, input.dataset.attribute, '');
      }

      if (input.dataset.attribute === 'quantity.unit') {
        unit = input;
      } else if (input.dataset.attribute === 'product.packaging.pieces') {
        pieces = input;
      } else if (input.dataset.attribute === 'product.currency') {
        currency = input;
      } else if (input.dataset.attribute === 'product.exchangeRate') {
        exchangeRate = input;
      }
    });

    if (currency && exchangeRate) {
      if (currency.value === 'zł') {
        exchangeRate.disabled = true;
      }
    }
  }

  async function patchOrder(event) {
    if (typeof _.get(event, 'target.dataset.attribute') !== 'string') {
      return;
    }
    const url = `/api/orders/${order._id}`;
    // Look for array expressions, like "product.film.front[1]"
    // When this regular expression is matched, it means we modify array.
    const re = /([a-zA-Z0-9\.]+){1}\[\d\]/;
    let body = {
      [event.target.dataset.attribute]: event.target.value
    };

    // Unit and pieces attributes are related.
    // If one changes to 1000, the other should change too
    if (!unit || !pieces) {
      return;
    } else if (body['quantity.unit'] === 'K') {
      body['product.packaging.pieces'] = 1000;
      pieces.value = 1000;
    } else if (body['product.packaging.pieces'] == 1000) {
      body['quantity.unit'] = 'K';
      unit.value = 'K';
    } else if (body['product.packaging.pieces'] &&
               body['product.packaging.pieces'] !== 1000) {
      body['quantity.unit'] = 'pkg';
      unit.value = 'pkg';
    }

    // Currency and exchangeRate attributes are related as well
    // if currency is zł, exchangeRate is empty and disabled, otherwise editable
    if (currency && exchangeRate) {
      if (body['product.currency'] === 'zł') {
        body['product.exchangeRate'] = '';
        exchangeRate.value = '';
        exchangeRate.disabled = true;
      } else if (body['product.currency'] === '€') {
        exchangeRate.value = await getCurrencyExchangeRate();
        body['product.exchangeRate'] = exchangeRate.value;
        exchangeRate.disabled = false;
      } else if (body['product.currency'] || body['product.curreny'] === '') {
        exchangeRate.disabled = false;
      }
    }

    // Modified attribute is array
    // Update order instance first, then send entire array in PATCH request.
    // match[1] contains array name.
    let match = event.target.dataset.attribute.match(re);
    if (match && match.length == 2) {
      _.set(order, event.target.dataset.attribute, event.target.value);
      body = {
        [match[1]]: _.get(order, match[1])
      };
    }
    await axios.patch(url, body);
    for (let [attribute, value] of Object.entries(body)) {
      _.set(order, attribute, value);
    }
    calculateAttributes(content, order);
  }

  function onShowPrintTab(event) {
    event.preventDefault();
    try {
      if (!printPdfMake) throw new Error('Missing pdf-make element');
      printPdfMake.docDefinition = pdfOrder(order);
    } catch (e) {
      console.error(e);
    }
  }

  async function getCurrencyExchangeRate() {
    try {
      const res = await axios.get('/api/currency/EUR');
      if (res.data) {
        return parseFloat(res.data.exchange);
      }
    } catch (e) {
      console.error(e);
    }

    return 0;
  }

  async function onPaletteRadioChange(event) {
    console.log({
      message: 'onPaletteRadioChange',
      event,
      order
    });
    updatePdfPalette(order, event.target.value);
  }

  function onShowPaletteTab(event) {
    event.preventDefault();
    try {
      updatePdfPalette(order);
    } catch (e) {
      console.error(e);
    }
  }

  function onShowRollerTab(event) {
    event.preventDefault();
    try {
      updatePdfRoller(order);
    } catch (e) {
      console.error(e);
    }
  }

  function updatePdfPalette(order, rows=1) {
    if (!palettePdfMake) throw new Error('Missing pdf-make element');
    palettePdfMake.docDefinition = pdfPalette(order, rows);
  }

  function updatePdfRoller(order) {
    if (!pdfRoller) throw new Error('Missing pdf-make element');
    rollerPdfMake.docDefinition = pdfRoller(order);
  }
}

async function init(content) {
  const _id = getId(window.location.pathname);
  try {
    const res = await axios.get(`/api/orders/${_id}`);
    const order = res.data;
    setupContent(content, order);
  } catch (e) {
    console.error(e);
  }
}

module.exports.init = init;
