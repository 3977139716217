const agGrid2Pdf = require('../ag-grid-to-pdf');
class AgGridPrintElement extends HTMLAnchorElement {
  constructor() {
    super();
    this._grid = null;

    this._clickHandler = event => {
      event.preventDefault();
      console.log('print grid', this._grid);
      agGrid2Pdf(this._grid);
    }
  }

  connectedCallback() {
    this._grid = this._getGrid();
    this.addEventListener('click', this._clickHandler);
  }

  disconnectedCallback() {
    this._grid = null;
    this.removeEventListener('click', this._clickHandler);
  }

  _getGrid() {
    if (this._grid) return this._grid;
    if (this.getAttribute('for')) {
      this._grid = document.getElementById(this.getAttribute('for'));
    } else {
      this._grid = document.querySelector('ag-grid');
    }
    return this._grid;
  }
}

customElements.define('ag-grid-print', AgGridPrintElement, { extends: 'a' });

module.exports = {
  AgGridPrintElement
};
