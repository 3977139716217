const _ = require('lodash');
const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');

function makeRow(who='') {
  return {
    who,
    date: '',
    quantity: 0,
    kg: 0,
    days: '',
    name: '',
    orderId: '',
    entries: []
  };
}

async function fetchEmployeeMap() {
  const map = new Map();
  const response = await axios.get('/api/inventory/employees');
  response.data
    .filter(e => e.name)
    .forEach(e => map.set(e._id, e.name));

  return map;
}

function getOrderIds(additions) {
  return additions .map(a => parseInt(a.orderId))
    .filter(id => !isNaN(id));
}

async function fillMapWithOrders(map, orderIds) {
  try {
    const params = {
      params: {
        orderIds: orderIds.join(',')
      }
    }
    const response = await axios.get('/api/orders/', params);
    response.data.forEach(order => {
        var width     = parseFloat(_.get(order, 'product.size.width') || 0),
            height    = parseFloat(_.get(order, 'product.size.height') || 0),
            fold      = parseFloat(_.get(order, 'product.size.fold') || 0),
            density   = parseFloat(_.get(order, 'product.film.density') || 0),
            thickness = parseFloat(_.get(order, 'product.film.thickness') || 0),
            punch     = parseFloat(_.get(order, 'product.film.punch') || 0),
            pieces    = parseFloat(_.get(order, 'product.packaging.pieces') || 0),
            weight    = ((width + 2 * fold) * 2 * height *
                         density * thickness * pieces * ((100 - punch) / 100)) / 10000000;
        map.set(order.id, weight);
    })
  } catch(e) {
    console.error(e);
  }
}

async function fillMapWithProducts(map) {
  try {
    const products = await axios.get('/api/products');
    products.data.forEach(p => map.set(p.name, p.weight));
  } catch (e) {
    console.error(e);
  }
}

async function fillGrid(grid, year, month) {
  const map = new Map();
  const startDate = new Date(parseInt(year), parseInt(month), 1);
  const endDate = new Date(parseInt(year), parseInt(month) + 1, 1);
  endDate.setDate(endDate.getDate() - 1);

  const params = {
    params: {
      startDate: new DateOnly(startDate).toISOString(),
      endDate: new DateOnly(endDate).toISOString(),
      pieces: true,
    }
  };

  try {
    const employeeMap = await fetchEmployeeMap();
    const weightMap = new Map();
    const response = await axios.get('/api/inventory/additions', params);
    const additions = response.data.filter(addition => addition.who);

    await fillMapWithProducts(weightMap);
    await fillMapWithOrders(weightMap, getOrderIds(additions));

    additions.forEach(addition => {
        const who = employeeMap.get(addition.who) || addition.who;
        const quantity = (addition.quantity * addition.pieces || 0) / 1000;
        const date = new DateOnly(addition.date).toISOString();
        const kg = addition.quantity * weightMap.get(addition.orderId || addition.name) || 0;
        
        if (!map.has(who)) {
          map.set(who, makeRow(who));
        }
        const row = map.get(who);
        row.quantity += quantity;
        row.kg += kg;
        if (!row.entries.length || row.entries[0].date !== date) {
          row.entries.unshift(makeRow(date));
          row.entries[0].date = date;
        }
        const additionRow = makeRow(date);
        additionRow.orderId = addition.orderId;
        additionRow.name = addition.name;
        additionRow.quantity = addition.quantity * (addition.pieces || 1000.) / 1000.0;
        additionRow.kg = kg;
        row.entries[0].quantity += additionRow.quantity;
        row.entries[0].kg += kg;
        row.entries[0].entries.push(additionRow);
      });

    const rowData = Array.from(map.values()).map(row => {
      if (row.entries) {
        row.days = row.entries.length;
        row.entries.forEach(entry => {
          if (entry.entries && entry.entries.length == 1) {
            entry.name = entry.entries[0].name;
            entry.orderId = entry.entries[0].orderId;
            entry.who = entry.date;
            entry.entries = [];
          }
        });
        return row;
      }
    });
    rowData.sort((a, b) => {
      if (a.who === b.who) return 0;
      return a.who > b.who ? 1 : -1;
    });
    
    grid.api.setRowData(rowData);
  } catch (e) {
    console.error(e);
  }
}

module.exports.init = function init(content) {
  const grid = content.querySelector('ag-grid');
  const monthSelect = content.querySelector('#month');
  const yearSelect = content.querySelector('#year');
  const today = new Date();

  for (let year = today.getFullYear();year >= 2016; --year) {
    let option = document.createElement('option');
    option.innerText = year;
    yearSelect.add(option);
  }

  monthSelect.value = today.getMonth();
  const gridOptions = {
    columnDefs: [
      { headerName: 'Pracownik', field: 'who', width: 185, cellRenderer: 'group' },
      { headerName: 'Ilość w tys.', field: 'quantity', width: 100, cellClass: 'align-right',
        cellFormatter: node => node.value.toFixed(2)
      },
      { headerName: 'Waga w kg', field: 'kg', width: 100, cellClass: 'align-right',
        cellFormatter: node => node.value.toFixed(2)
      },
      { headerName: 'Dniówki', field: 'days', width: 100, cellClass: 'align-right' },
      { headerName: 'Towar', field: 'name' },
      { headerName: 'Zam.', field: 'orderId', width: 105, cellClass: 'align-right' },
    ],
    rowData: null,
    rowSelection: 'multiple',
    enableColResize: true,
    animateRows: true,
    getNodeChildDetails: function(rowItem) {
      // First level of grouping by who column
      if (rowItem.who && rowItem.entries && rowItem.entries.length) {
        return {
          group: true,
          expanded: false,
          children: rowItem.entries,
          field: 'who',
          key: rowItem.who,
        }
        // Second level of grouping by day. Only if there is more than one product
      } else if (rowItem.date && rowItem.entries && rowItem.entries.length) {
        return {
          group: true,
          expanded: false,
          children: rowItem.entries,
          field: 'date',
          key: rowItem.date,
        };
      } else {
        return null;
      }
    },
    onGridReady: () => {
      viewChangeCallback();
      grid.api.sizeColumnsToFit();
    },
  };

  grid.gridOptions = gridOptions;

  const toggleCallback = toggle.addListener(() => grid.api.sizeColumnsToFit());

  yearSelect.addEventListener('change', viewChangeCallback);
  monthSelect.addEventListener('change', viewChangeCallback);

  content.addEventListener('unload', () => {
    yearSelect.removeEventListener('change', viewChangeCallback);
    monthSelect.removeEventListener('change', viewChangeCallback);
    toggle.removeListener(toggleCallback);
  });

  function viewChangeCallback() {
    fillGrid(grid, yearSelect.value, monthSelect.value);
  }
}
