const pdfMake = require('pdfmake/build/pdfmake.min.js');

function columnWidthsAsPercentage(grid) {
  const totalWidth = grid.clientWidth;
  const columnWidths = grid.columnApi.getAllColumns()
    .map(col => {
      if (!col.colDef || !col.colDef.width) return '*'
      return parseInt((col.colDef.width * 100) / totalWidth).toString() + '%';
    });

  return columnWidths;
}

function columnData(colDef, rowData) {
  if (typeof colDef.cellRenderer === 'function' && typeof colDef.cellRenderer.prototype.render === 'function') {
    return colDef.cellRenderer.prototype.render(rowData[colDef.field])
  }
  return rowData[colDef.field] || ''
}

function tableDefinition(grid) {
  let table = {
	headerRows: 1,
	color: '#444',
	widths: [],
	body: []
  };

  if (!grid && !Array.isArray(grid.columnDefs)) return null;

  let tableLength = grid.columnApi.getAllColumns().length;
  let colDefs = grid.columnApi.getAllColumns()
    .map(col => col.colDef);
  let tableWidth = colDefs
	.map(colDef => colDef.width)
	.reduce((a, b) => a + (b || 0), 0);

  table.widths = columnWidthsAsPercentage(grid);


  table.body = table.body.concat([colDefs.map(colDef => {
	return { text: colDef.headerName || colDef.field, style: 'header' };
  })]);

  let tableRow = rowData => colDefs.map(colDef => columnData(colDef, rowData));

  table.body = table.body.concat(grid.api.rowModel.rowsToDisplay
	.map(row => tableRow(row.data)));

  let bottomRowCount = grid.api.getPinnedBottomRowCount();
  for (let i = 0; i< bottomRowCount; ++i) {
    let bottomRow = grid.api.getPinnedBottomRow(i).data;
    let row = colDefs.map(colDef => {
      let text;
      if (typeof colDef.pinnedRowCellRenderer === 'function') {
	text = colDef.pinnedRowCellRenderer(bottomRow[colDef.field]);
      } else {
	text = columnData(colDef, bottomRow);
      }
      return {text, style: 'pinnedRow'};
    })
    console.log(row);
    table.body = table.body.concat([row]);
  }

  return { table };
}

module.exports = function agGrid2Pdf(grid) {
  let pageSize = 'A4';
  let pageMargins = [40, 60, 40, 60];
  let content = [];
  let header = grid.getAttribute('title') ? { text: grid.getAttribute('title'), style: 'title' } : undefined;
  let footer = function(currentPage, pageCount) {
	return {
	  text: `${currentPage} / ${pageCount}`,
	  style: 'footer'
	};
  };
  let styles = {
	title: {
	  bold: true,
	  alignment: 'center',
	  fontSize: 16,
	  margin: [0, 30, 0, 20]
	},
	header: {
	  bold: true
	},
	pinnedRow: {
	  bold: true
	},
	footer: {
	  alignment: 'center',
	  fontSize: 10
	}
  };
  let defaultStyle = {
	font: 'DejaVuSans',
	fontSize: 10
  };

  if (grid.heading) {
	content.push(grid.heading);
  }
  let table = tableDefinition(grid);
  if (table) {
	content.push(table);
  } else {
	content.push({ text: 'Brak danych do wyświetlenia' });
  }
  let dd = {
	pageSize,
	pageMargins,
	header,
	content,
	footer,
	styles,
	defaultStyle
  };

  console.log(dd);
  pdfMake.createPdf(dd).print();
}
