const _ = require('lodash');
const axios = require('axios');

async function init(content) {
  const form = content.querySelector('#currency-form');
  const saveButton = content.querySelector('#save');
  const lastUpdateText = content.querySelector('#last-update');
  const exchangeInput = content.querySelector('#exchange');

  form.addEventListener('submit', exchangeSubmit);

  try {
    const res = await axios.get('/api/currency/EUR');
    if (res.data) {
      updateForm(res.data);
    }
  } catch(e) {
    console.error(e);
  }

  function updateForm(data) {
    let date = new Date(data.lastUpdate).toLocaleString();
    exchangeInput.value = _.get(data, 'exchange', '');
    lastUpdateText.innerText = date;
  }

  async function exchangeSubmit(event) {
    event.preventDefault();

    const data = {
      symbol: "EUR",
      exchange:  exchangeInput.value
    };

    try {
      const response = await axios.put('/api/currency/EUR', data);
      updateForm(response.data);
    } catch (e) {
      console.log(e);
    }
  }
}

exports.init = init;
