const axios = require('axios');
const DateOnly = require('dateonly');
const toggle = require('./toggle');
const ProductTypeFilter = require('./grid/product-type-filter');
const ProductLinkRenderer = require('./grid/product-link-cell-renderer');
const NumberCellRenderer = require('./grid/number-cell-renderer');
const ExpressionCellEditor = require('./grid/expression-cell-editor');
const getSnapshotUrl = require('./util/get-snapshot-url');
const getOrderSnapshotUrl = require('./util/get-alt-order-snapshot-url');

module.exports.init = function init(content) {
  const [_1_, _2_, _3_, year, month, day, _4_] = location.pathname.split('/');
  const grid = content.querySelector('#stocktaking-grid');
  const showall = content.querySelector('#stocktaking-showall');
  content.querySelector('#calendar').innerHTML = `&nbsp;${day}/${month}/${year}`;
  grid.setAttribute('title', `Remanent z dnia ${day}/${month}/${year}`);
  grid.gridOptions = {
    enableFilter: true,
    columnDefs: [
      {
        headerName: 'Produkt',
        field: 'name',
        filter: ProductTypeFilter,
        cellRenderer: ProductLinkRenderer,
        editable: false
      },
      {
        headerName: 'Zam.',
        field: 'orderId',
        suppressFilter: true,
        width: 140,
        cellClass: 'align-right',
        editable: false,
      },
      {
        headerName: 'Stan wyliczony',
        field: 'snapshot',
        suppressFilter: true,
        width: 140,
        editable: false,
        cellClass: 'align-right',
        cellRenderer: NumberCellRenderer,
      },
      {
        headerName: 'Stan rzeczywisty',
        field: 'stocktaking',
        suppressFilter: true,
        width: 140,
        editable: true,
        cellEditor: ExpressionCellEditor,
        cellClass: 'align-right',
      }
    ],
    isExternalFilterPresent: () => {
      let showall = document.getElementById('stocktaking-showall');
      return showall && !showall.checked;
    },
    doesExternalFilterPass: node => {
      if (!node.data) { return false; }
      return node.data.snapshot || node.data.stocktaking;
    },
    onCellEditingStopped: params => {
      if (params.colDef.field !== 'stocktaking') return;
      const id = params.data.productId || params.data.orderId;
      const url = `/api/inventory/stocktaking/${year}/${month}/${day}/${id}`;
      axios.put(url, {quantity: parseFloat(params.data.stocktaking)});
    },
    rowData: null,
    onGridReady: async () => {
      const date = `${year}-${month}-${day}`;
      const snapshotUrl = getSnapshotUrl(date);
      const orderSnapshotUrl = getOrderSnapshotUrl(date);
      const dateVal = new DateOnly(date).valueOf();
      const snapshotMap = new Map();
      const stocktakingMap = new Map();
      const params = {
        params: {
          startDate: date,
          endDate: date,
        }
      };
      const [products, orders, stocktakings, snapshots, orderSnapshots] =
        await Promise.all([
          axios.get('/api/products'),
          axios.get('/api/orders', {params: {status: 'active'}}),
          axios.get('/api/inventory/stocktaking', params),
          axios.get(snapshotUrl),
          axios.get(orderSnapshotUrl)
        ]);
      snapshots.data.forEach(s => snapshotMap.set(s.productId, s.items));
      orderSnapshots.data.forEach(s => snapshotMap.set(parseInt(s._id), s.items));
      stocktakings.data
        .filter(s => s.date == dateVal)
        .forEach(s => stocktakingMap.set(s.productId, s.quantity));
      const rowData = products.data.map(p => Object.create({
        productId: p._id,
        name: p.name,
        snapshot: snapshotMap.get(p._id) || '',
        stocktaking: stocktakingMap.get(p._id) || '',
      }));
      orders.data.forEach(order => {
        try {
          rowData.push({
            orderId: order.id,
            name: order.product.name,
            snapshot: snapshotMap.get(order.id) || '',
            stocktaking: stocktakingMap.get(order.id) || '',
          });
        } catch (e) {}
      });
      grid.api.setRowData(rowData);
      grid.api.sizeColumnsToFit();
    }
  };
  showall.addEventListener('change', () => grid.api.onFilterChanged());
  const toggleHandler = toggle.addListener(() => grid.api.sizeColumnsToFit());
  content.addEventListener('unload', () => {
    toggle.removeListener(toggleHandler);
  });
}
